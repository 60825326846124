import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import signin_en from "./en/signin.json";
import signup_en from "./en/signup.json";
import resetPassword_en from "./en/resetPassword.json";
import myDishes_en from "./en/myDishes.json";
import myDish_en from "./en/myDish.json";
import addADish_en from "./en/addADish.json";
import chef_en from "./en/chef.json";
import categories_en from "./en/categories.json";
import diets_en from "./en/diets.json";
import dishTypes_en from "./en/dishTypes.json";
import allergenes_en from "./en/allergenes.json";
import common_en from "./en/common.json";
import changePassword_en from "./en/changePassword.json";

export const resources = {
  en: {
    signin: signin_en,
    resetPassword: resetPassword_en,
    signup: signup_en,
    myDishes: myDishes_en,
    myDish: myDish_en,
    addADish: addADish_en,
    chef: chef_en,
    categories: categories_en,
    diets: diets_en,
    dishTypes: dishTypes_en,
    allergenes: allergenes_en,
    common: common_en,
    changePassword: changePassword_en,
  },
} as const;

export const defaultNS = "common";

export const checkNamespaces = () => {
  // const t: typeof resources.en = resources.fr;
};

i18n.use(initReactI18next).init({
  lng: "en",
  ns: ["common"],
  resources,
  defaultNS,
  interpolation: { escapeValue: false },
});
