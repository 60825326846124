import { LockClosedIcon } from "@heroicons/react/solid";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { CodeInput } from "components/form/CodeInput";
import { useState } from "react";
import { API } from "sdk";

const resetPassword = async (data: { email: string }) => ({ email: "string" });
type GetDataType<T extends (arg: any) => any> = T extends (arg: infer Data) => any ? Data : never;

export const ResetPassword = () => {
  const { t } = useTranslation("resetPassword");
  const [code, setCode] = useState("");
  const { register, handleSubmit } = useForm<GetDataType<typeof resetPassword>, any>();

  console.log(code);
  const submit = resetPassword;

  return (
    <div className="flex w-full max-h-screen ">
      <div className="flex flex-1">
        <img src={require("assets/signin.jpg")} className="object-cover w-full h-screen" />
      </div>
      <div className="flex items-center justify-center flex-1 min-h-full px-4 py-12 sm:px-6 lg:px-8">
        <div className="w-full max-w-md space-y-8">
          <div>
            <h2 className="mt-6 text-4xl font-extrabold">{t("Forgotten password")}</h2>
            <p className="mt-8 text-lg">{t("Forgotten password description")}</p>
          </div>
          <form className="mt-8 space-y-6" onSubmit={handleSubmit(submit)}>
            <input type="hidden" name="remember" defaultValue="true" />
            <div className="-space-y-px rounded-md">
              <div>
                <label htmlFor="email-address" className="font-semibold">
                  {t("Email address")}
                </label>
                <input
                  id="email-address"
                  type="email"
                  autoComplete="email"
                  required
                  className="relative block w-full px-3 py-2 mt-1 mb-4 text-gray-900 placeholder-gray-500 border border-gray-200 rounded-md appearance-none bg-gray-25 focus:outline-none focus:ring-main focus:border-main focus:z-10 sm:text-sm"
                  placeholder={t("Email address")}
                  {...register("email")}
                />
              </div>
              <div className="pb-2">
                <label htmlFor="email-address" className="font-semibold">
                  {t("Enter the recevied 6-digit code")}
                </label>
              </div>
              <CodeInput setValue={setCode} n={6} />
            </div>
            <div>
              <button
                type="submit"
                className="relative flex justify-center w-full px-4 py-2 text-sm font-medium text-white border border-transparent rounded-md bg-main group hover:bg-main focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-main"
              >
                <span className="absolute left-0 flex items-center pl-3 insets-y-0">
                  <LockClosedIcon className="w-5 h-5 text-main group-hover:text-mainLight" aria-hidden="true" />
                </span>
                {t("Reset password")}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
