import { useLocation, useNavigate } from "react-router-dom";
import { useGlobalState } from "hooks/useGlobalState";
import { useEffect } from "react";

export const useRedirectIfNotAuthenticated = () => {
  const [chef] = useGlobalState("chef");
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (chef.isAuthenticated === false) navigate(`/signin?from=${location.pathname}`);
  }, [chef.isAuthenticated]);
};
