import z from "zod";
import { useState } from "react";
import { useGlobalState } from "hooks/useGlobalState";

import { Link } from "react-router-dom";
import { ExclamationIcon, TrashIcon, XIcon } from "@heroicons/react/outline";
import { Overlay } from "components/utils/Overlay";

import { useQuery, useAuth, useMutation } from "hooks/useBridge";
import { useForm } from "hooks/useForm";
import { useController } from "react-hook-form";

import { useTranslation } from "react-i18next";

import { API } from "sdk";
import { ChefType } from "types/api";
import { zodResolver } from "@hookform/resolvers/zod";

import { extract } from "../../utils/Object";
import { InputCoverImage, InputProfileImage } from "components/form/InputImage";
import defaultChef from "assets/defaultChef.png";
import { getDayOfWeekString } from "utils/Date";
import { Spinner } from "components/Spinner";

export const Chef = () => {
  const [chef] = useGlobalState("chef");
  const [editProfileOpen, setEditProfileOpen] = useState(false);
  const { t } = useTranslation("chef");

  const getMe = useAuth(API.chef.getMe);
  const { data: me, refetch } = useQuery(["getMe", chef.isAuthenticated], () => getMe({}), {
    enabled: !!chef.isAuthenticated,
  });

  if (!me) return <div />;

  return (
    <div className="-mt-8">
      <div className="w-full bg-white border-b border-gray-200">
        <div className="flex flex-col w-full grid-cols-12 gap-2 px-8 py-8 mx-auto mt-4 md:gap-8 md:items-center md:flex-row">
          {me.profileImage ? (
            <img
              src={me.profileImage}
              className="flex-shrink-0 object-cover w-24 h-24 border-2 border-white rounded-full"
            />
          ) : (
            <img
              src={defaultChef}
              className="flex-shrink-0 object-cover w-24 h-24 border-2 border-white rounded-full"
            />
          )}
          <div className="flex flex-col ">
            <p className="text-lg font-bold">
              {me.firstName} {me.lastName}
            </p>
            <p className="line-clamp-2">{me.description}</p>
          </div>
          <div className="flex md:ml-auto">
            <div className="flex items-center mt-2 ">
              <button
                type="button"
                onClick={() => setEditProfileOpen(true)}
                className="inline-flex justify-center w-full px-4 py-2 ml-auto text-base font-medium text-white border border-transparent rounded-md whitespace-nowrap bg-main hover:bg-main focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-main sm:w-auto sm:text-sm"
              >
                {t("Edit profile")}
              </button>
            </div>
            <EditProfileModal open={editProfileOpen} setOpen={setEditProfileOpen} chef={me} refetch={refetch} />
          </div>
        </div>
      </div>
      <div className="flex flex-col items-start w-full grid-cols-12 gap-8 px-8 mx-auto mt-8 sm:grid">
        <div className="flex flex-col w-full col-span-12 gap-4 md:col-span-4">
          <div className="flex flex-col p-4 bg-white border border-gray-200 rounded-md ">
            <p className="font-bold ">{t("Chef's availabilities")}</p>
            <div className="w-8 border-t-2 rounded-md border-main" />
            <Availabilities chef={me} refetch={refetch} />
          </div>
        </div>
        <div className="flex flex-col col-span-12 py-4 bg-white border border-gray-200 rounded-md md:col-span-8 flex-0">
          <div className="flex">
            <div>
              <p className="px-4 font-bold ">{t("Chef's dishes")}</p>
              <div className="w-8 ml-4 border-t-2 rounded-md border-main" />
            </div>
            <a href="/add-a-dish" className="ml-auto mr-4">
              <button
                type="button"
                className="inline-flex justify-center w-full px-4 py-2 text-base font-medium text-white border border-transparent rounded-md whitespace-nowrap bg-main hover:bg-main focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-main sm:w-auto sm:text-sm"
              >
                {t("Add a dish")}
              </button>
            </a>
          </div>
          <DishesTable dishes={me.dishes} refetch={refetch} />
        </div>
      </div>
    </div>
  );
};

interface EditProfileModalProps {
  open: boolean;
  setOpen: (o: boolean) => void;
  chef: ChefType;
  refetch: () => any;
}

const EditProfileModal = ({ open, setOpen, chef, refetch }: EditProfileModalProps) => {
  const { t } = useTranslation("chef");
  const authentifiedUpdateMe = useAuth(API.chef.updateMe);
  const authentifiedUpdateImages = useAuth(API.chef.updateImages);
  const [deletedCoverImage, setDeletedCoverImage] = useState(false);
  const [deletedProfileImage, setDeletedProfileImage] = useState(false);

  const { mutate: updateMe, isLoading } = useMutation(authentifiedUpdateMe, {
    onSuccess: () => {
      const updateData = {} as any;
      if (profileImage) updateData.profileImage = profileImage;
      if (coverImage) updateData.coverImage = coverImage;
      if (profileImage || coverImage) updateImages({ query: { chefId: chef._id }, files: updateData });
      else {
        setOpen(false);
        refetch();
      }
    },
  });

  const { register, handleSubmit, reset } = useForm(updateMe, {
    resolver: (data, context, options) => {
      const nData = { body: { ...data.body }, query: { chefId: chef._id } };
      if (deletedCoverImage) nData.body.coverImage = "";
      if (deletedProfileImage) nData.body.profileImage = "";
      return zodResolver(
        z.object({ body: API.chef.zodSchemas.updateMe.body, query: z.object({ chefId: z.string() }) })
      )(nData, context, options);
    },
    defaultValues: {
      body: extract(chef, ["firstName", "lastName", "disponibilityString", "address", "description", "phone"]),
    },
  });

  const { mutate: updateImages, isLoading: isLoadingImage } = useMutation(authentifiedUpdateImages, {
    onSuccess: () => {
      setOpen(false);
      refetch();
    },
  });

  const [profileImage, setProfileImage] = useState<File | null>(null);
  const [coverImage, setCoverImage] = useState<File | null>(null);

  return (
    <Overlay open={open} setOpen={setOpen}>
      <form className="px-4 pt-5 pb-4 bg-white sm:p-6 sm:pb-4" id="edit-profile" onSubmit={handleSubmit}>
        <div className="sm:flex sm:items-center">
          <div className="mt-3 text-center sm:mt-0 sm:text-left">
            <h3 className="text-lg font-medium leading-6q" id="modal-title">
              {t("Edit profile")}: {chef.firstName} {chef.lastName}
            </h3>
          </div>
          <XIcon
            className="w-8 h-8 p-1 ml-auto text-gray-900 rounded-md cursor-pointer hover:bg-gray-50"
            onClick={() => {
              setOpen(false);
              reset({
                body: extract(chef, [
                  "firstName",
                  "lastName",
                  "disponibilityString",
                  "address",
                  "description",
                  "phone",
                ]),
              });
            }}
          />
        </div>
        <div>
          <InputCoverImage
            image={coverImage}
            setImage={setCoverImage}
            initialImage={chef.coverImage}
            deleted={deletedCoverImage}
            setDeleted={setDeletedCoverImage}
          />
          <div className="relative ml-4 -mt-16">
            <InputProfileImage
              image={profileImage}
              setImage={setProfileImage}
              initialImage={chef.profileImage}
              deleted={deletedProfileImage}
              setDeleted={setDeletedProfileImage}
            />
            {((chef.profileImage && !deletedProfileImage) || profileImage) && (
              <TrashIcon
                className="absolute p-1 text-white bg-red-500 border-2 border-red-500 rounded-full cursor-pointer hover:border-white top-2 left-16 w-7 h-7"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  setProfileImage(null);
                  setDeletedProfileImage(true);
                }}
              />
            )}
          </div>
        </div>
        <div className="flex flex-col gap-4 mt-4 sm:flex-row">
          <div className="flex flex-col flex-1">
            <label htmlFor="first-name" className="font-semibold ">
              {t("First name")}
            </label>
            <input
              id="first-name"
              type="text"
              autoComplete="firstName"
              required
              className="relative block w-full px-3 py-2 mt-1 text-gray-900 placeholder-gray-500 border border-gray-300 rounded-md appearance-none focus:outline-none focus:ring-main focus:border-main focus:z-10 sm:text-sm"
              placeholder={t("First name")}
              {...register("body.firstName")}
            />
          </div>
          <div className="flex flex-col flex-1">
            <label htmlFor="first-name" className="font-semibold ">
              {t("Last name")}
            </label>
            <input
              id="last-name"
              type="text"
              autoComplete="lastName"
              required
              className="relative block w-full px-3 py-2 mt-1 text-gray-900 placeholder-gray-500 border border-gray-300 rounded-md appearance-none focus:outline-none focus:ring-main focus:border-main focus:z-10 sm:text-sm"
              placeholder={t("Last name")}
              {...register("body.lastName")}
            />
          </div>
        </div>
        <div className="mt-4">
          <label htmlFor="description" className="mt-4 font-semibold ">
            {t("Description")}
          </label>
          <p className="text-sm text-gray-700">{t("DescriptionDescription")}</p>
          <div className="flex gap-4 mt-2">
            <textarea
              id="description"
              rows={5}
              className="block w-full border border-gray-300 rounded-md shadow-sm focus:ring-main focus:border-main sm:text-sm"
              placeholder={t("Description")}
              defaultValue={""}
              {...register("body.description")}
            />
          </div>
        </div>
        <div className="flex gap-4">
          <div className="flex flex-col flex-1">
            <label htmlFor="first-name" className="mt-4 font-semibold ">
              {t("Phone number")}
            </label>
            <input
              id="phone-number"
              type="text"
              autoComplete="phoneNumber"
              className="relative block w-full px-3 py-2 mt-1 text-gray-900 placeholder-gray-500 border border-gray-300 rounded-md appearance-none focus:outline-none focus:ring-main focus:border-main focus:z-10 sm:text-sm"
              placeholder={t("Phone number")}
              {...register("body.phone")}
            />
          </div>
        </div>
      </form>
      <div className="px-4 py-3 bg-gray-50 sm:px-6 sm:flex sm:flex-row-reverse">
        {!(isLoading || isLoadingImage) ? (
          <button
            type="submit"
            className="inline-flex justify-center w-full px-4 py-2 text-base font-medium text-white border border-transparent rounded-md bg-main hover:bg-main focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-main sm:ml-3 sm:w-auto sm:text-sm"
            form="edit-profile"
          >
            {t("Save")}
          </button>
        ) : (
          <button className="inline-flex justify-center w-full px-4 py-2 text-base font-medium text-white border border-transparent rounded-md bg-mainLight hover:bg-main focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-main sm:ml-3 sm:w-auto sm:text-sm">
            <span className="flex items-center insets-y-0">
              <Spinner />
            </span>
            {t("Save")}
          </button>
        )}
        <button
          type="button"
          onClick={() => {
            setOpen(false);
            reset({
              body: extract(chef, ["firstName", "lastName", "disponibilityString", "address", "description", "phone"]),
            });
          }}
          className="inline-flex justify-center w-full px-4 py-2 mt-3 text-base font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-main sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
        >
          {t("Cancel")}
        </button>
      </div>
    </Overlay>
  );
};

interface ConfirmDeletionModalProps {
  open: boolean;
  setOpen: (o: boolean) => void;
  dish: ChefType["dishes"][number];
  refetch: () => any;
}

const ConfirmDeletionModal = ({ open, setOpen, dish, refetch }: ConfirmDeletionModalProps) => {
  const { t } = useTranslation("chef");

  const deleteByChefWithToken = useAuth(API.dish.deleteByChef);

  const { mutate: deleteByChef } = useMutation(deleteByChefWithToken, {
    onSuccess: (res) => {
      setOpen(false);
      refetch();
    },
  });

  return (
    <Overlay open={open} setOpen={setOpen}>
      <div className="px-4 pt-5 pb-4 bg-white sm:p-6 sm:pb-4">
        <div className="sm:flex sm:items-start">
          <div className="flex items-center justify-center flex-shrink-0 w-12 h-12 mx-auto bg-red-100 rounded-full sm:mx-0 sm:h-10 sm:w-10">
            <ExclamationIcon className="w-8 h-8 text-red-600" />
          </div>
          <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
            <h3 className="text-lg font-medium leading-6 text-gray-900" id="modal-title">
              {t("DeleteDish")}: {dish.byChef.name}
            </h3>
            <div className="mt-2">
              <p className="text-sm text-gray-500">{t("DeleteExplanation")}</p>
            </div>
          </div>
        </div>
      </div>
      <div className="px-4 py-3 bg-gray-50 sm:px-6 sm:flex sm:flex-row-reverse">
        <button
          type="button"
          onClick={() => {
            deleteByChef({ query: { dishId: dish._id } });
          }}
          className="inline-flex justify-center w-full px-4 py-2 text-base font-medium text-white bg-red-600 border border-transparent rounded-md hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
        >
          {t("Delete")}
        </button>
        <button
          type="button"
          onClick={() => setOpen(false)}
          className="inline-flex justify-center w-full px-4 py-2 mt-3 text-base font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-main sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
        >
          {t("Cancel")}
        </button>
      </div>
    </Overlay>
  );
};
const DishRow = ({ dish, refetch }: { dish: ChefType["dishes"][number]; refetch: () => {} }) => {
  const { t } = useTranslation("chef");
  const [openDelete, setOpenDelete] = useState(false);

  return (
    <div className="grid w-full grid-cols-9 gap-4 px-4 py-2 border-b border-gray-50">
      <div className="flex items-center col-span-6 gap-4">
        {dish.byChef.images?.[0] ? (
          <img src={dish.byChef.images[0]} className="flex-shrink-0 object-cover w-16 h-16 rounded-md" />
        ) : (
          <div className="flex-shrink-0 w-16 h-16 rounded-md bg-mainLight bg-opacity-20" />
        )}
        <Link className="flex flex-col flex-0" to={`/my-dishes/${dish._id}`}>
          <p className="font-bold text-main">{dish.byChef.name}</p>
          <p className="flex flex-1 text-sm text-gray-500 text text-ellipsis line-clamp-2">{dish.byChef.description}</p>
        </Link>
      </div>

      <div className="flex items-center justify-end col-span-2 text-right">
        <p className="">{dish.byChef.category}</p>
      </div>
      <div className="flex items-center justify-end col-span-1">
        <ConfirmDeletionModal open={openDelete} setOpen={setOpenDelete} dish={dish} refetch={refetch} />
        <TrashIcon
          className="w-8 p-1 text-gray-600 border-2 border-transparent rounded-md cursor-pointer bg-gray-50 hover:border-gray-200"
          onClick={() => setOpenDelete(true)}
        />
      </div>
    </div>
  );
};

export const DishesTable = ({ dishes, refetch }: { dishes: ChefType["dishes"][number][]; refetch: () => any }) => {
  const { t } = useTranslation("chef");
  return (
    <div className="">
      <div className="grid grid-cols-9 gap-4 px-4 py-2 mt-4 border-b border-gray-50">
        <div className="flex col-span-6 ">
          <div className="w-16 col-span-2">
            <p className="text-xs font-bold uppercase">{t("Image")}</p>
          </div>
          <div className="flex flex-1 col-span-6 ml-4 ">
            <p className="text-xs font-bold uppercase">{t("Details")}</p>
          </div>
        </div>
        <div className="flex justify-end col-span-2">
          <p className="text-xs font-bold uppercase">{t("Category")}</p>
        </div>
        <div className="flex justify-end col-span-1">
          <p className="text-xs font-bold uppercase">{t("Del.")}</p>
        </div>
      </div>
      {dishes && dishes.map((dish, i) => <DishRow dish={dish} key={i} refetch={refetch} />)}
      {(!dishes || !dishes.length) && (
        <div className="p-4 bg-gray-50">
          <p className="text-sm text-center">
            {t("You don't have any dish,")}{" "}
            <a href="/add-a-dish">
              <span className="font-bold text-main">{t("add one")}</span>
            </a>{" "}
            {t("to be on the Mama Food app")}
          </p>
        </div>
      )}
    </div>
  );
};

const Availabilities = ({ chef, refetch }: { chef: ChefType; refetch: () => any }) => {
  const { i18n, t } = useTranslation("chef");
  const [open, setOpen] = useState(false);
  return (
    <div className="">
      <EditAvailabilitiesModal chef={chef} open={open} setOpen={setOpen} refetch={refetch} />
      <div className="flex flex-wrap gap-2 mt-4">
        {chef.disponibilityDays?.map((d) => (
          <p className="p-1 px-2 text-sm border border-gray-200 rounded-md bg-gray-50">
            {getDayOfWeekString(d, i18n.language)}
          </p>
        ))}
        {(!chef.disponibilityDays || chef.disponibilityDays.length == 0) && (
          <p className="mt-2 text-sm">{t("No availabilities")}</p>
        )}
      </div>
      {chef.disponibilityString && (
        <div>
          <p className="mt-4 font-semibold">{t("Additional")}</p>
          <p className="mt-2 text-sm">{chef.disponibilityString}</p>
        </div>
      )}
      <p className="mt-4 font-semibold cursor-pointer text-main" onClick={() => setOpen(true)}>
        {t("Edit availabilities")}
      </p>
    </div>
  );
};

interface EditAvailabilitiesModalProps {
  open: boolean;
  setOpen: (o: boolean) => void;
  chef: ChefType;
  refetch: () => any;
}

const EditAvailabilitiesModal = ({ open, setOpen, chef, refetch }: EditAvailabilitiesModalProps) => {
  const { t, i18n } = useTranslation("chef");
  const tokenizedUpdateChef = useAuth(API.chef.updateMe);

  const { mutate: updateAvailabilities } = useMutation(tokenizedUpdateChef, {
    onSuccess: () => {
      setOpen(false);
      refetch();
    },
  });

  const { register, handleSubmit, reset, control, errors } = useForm(updateAvailabilities, {
    resolver: zodResolver(z.object({ body: API.chef.zodSchemas.updateMe.body })),
    defaultValues: { body: extract(chef, ["disponibilityString", "disponibilityDays"]) },
  });

  console.log("errors", errors);

  const { field } = useController({ name: "body.disponibilityDays", control });
  const { value: disponibilityDays, onChange: setDisponibilityDays } = field;

  return (
    <Overlay open={open} setOpen={setOpen}>
      <form className="px-4 pt-5 pb-4 bg-white sm:p-6 sm:pb-4" id="edit-profile" onSubmit={handleSubmit}>
        <div className="sm:flex sm:items-start">
          <div className="mt-3 text-center sm:mt-0 sm:text-left">
            <h3 className="text-lg font-medium leading-6q" id="modal-title">
              {t("Edit availabilities")}: {chef.firstName} {chef.lastName}
            </h3>
          </div>
        </div>

        <div className="flex flex-col">
          <label htmlFor="first-name" className="mt-4 font-semibold ">
            {t("Availabilities")}
          </label>
          <div className="flex flex-wrap gap-2 mt-2">
            {([1, 2, 3, 4, 5, 6, 0] as const).map((d) => (
              <p
                className={`p-1 px-2 cursor-pointer border  rounded-md ${
                  disponibilityDays?.includes(d) ? "bg-main text-white" : "bg-gray-50 border-gray-200"
                }`}
                onClick={() =>
                  setDisponibilityDays(
                    disponibilityDays?.includes(d)
                      ? disponibilityDays.filter((d2) => d2 !== d)
                      : [...(disponibilityDays || []), d]
                  )
                }
              >
                {getDayOfWeekString(d, i18n.language)}
              </p>
            ))}
          </div>
          <textarea
            id="availabilities"
            rows={5}
            className="block w-full mt-4 border border-gray-300 rounded-md shadow-sm focus:ring-main focus:border-main sm:text-sm"
            placeholder={t("Add a note")}
            defaultValue={""}
            {...register("body.disponibilityString")}
          />
        </div>
      </form>
      <div className="px-4 py-3 bg-gray-50 sm:px-6 sm:flex sm:flex-row-reverse">
        <button
          type="submit"
          className="inline-flex justify-center w-full px-4 py-2 text-base font-medium text-white border border-transparent rounded-md bg-main hover:bg-main focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-main sm:ml-3 sm:w-auto sm:text-sm"
          form="edit-profile"
        >
          {t("Save")}
        </button>
        <button
          type="button"
          onClick={() => {
            setOpen(false);
            reset();
          }}
          className="inline-flex justify-center w-full px-4 py-2 mt-3 text-base font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-main sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
        >
          {t("Cancel")}
        </button>
      </div>
    </Overlay>
  );
};
