import { BrowserRouter, Route, Routes } from "react-router-dom";
import { ChangePassword } from "./auth/ChangePassword";
import { Dashboard } from "./dashboard";
import { AddADish } from "./dashboard/AddADish";
import { MyDishes } from "./dashboard/MyDishes";
import { ResetPassword } from "./auth/ResetPassword";
import { Signin } from "./auth/Signin";
import { Signup } from "./auth/Signup";
import { useLocalStorageTokens } from "hooks/useLocalStorage";
import { Chef } from "./dashboard/Chef";
import { MyDish } from "./dashboard/MyDish";

export const Router = () => {
  useLocalStorageTokens();

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Dashboard />}>
          <Route path="" element={<Chef />} />
          <Route path="my-dishes" element={<MyDishes />} />
          <Route path="my-dishes/:id" element={<MyDish />} />
          <Route path="add-a-dish" element={<AddADish />} />
          <Route path="/change-password" element={<ChangePassword />} />
        </Route>
        {/* Auth */}
        <Route path="/signin" element={<Signin />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/reset-password" element={<ResetPassword />} />
      </Routes>
    </BrowserRouter>
  );
};
