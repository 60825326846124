import { TrashIcon } from "@heroicons/react/outline";
import { ChangeEvent, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import Compress from "react-image-file-resizer";

export const InputCoverImage = ({
  image,
  setImage,
  defaultImage,
  initialImage,
  deleted,
  setDeleted,
}: {
  image: File | null;
  setImage: (i: File | null) => void;
  defaultImage?: string;
  initialImage?: string;
  deleted: boolean;
  setDeleted: (b: boolean) => void;
}) => {
  const { t } = useTranslation("common");
  const inputRef = useRef<HTMLInputElement>(null);

  const uploadFile = (e: ChangeEvent<HTMLInputElement>) => {
    const im = e.target.files?.[0];
    if (im) handleImage(im);
  };

  // Handling the event of the image input
  const handleImage = async (file: File | null) => {
    if (!file) return;
    setDeleted(false);
    //@ts-ignore
    Compress.imageFileResizer(file, 540, 960, "JPEG", 80, 0, setImage, "file");
  };

  return (
    <div
      className="relative z-0 flex justify-center w-full h-auto mt-1 overflow-hidden bg-white cursor-pointer"
      onClick={() => inputRef.current?.click()}
      style={{ aspectRatio: "3" }}
    >
      {image ? (
        <img src={URL.createObjectURL(image)} className="object-cover w-full" style={{ aspectRatio: "3" }} />
      ) : initialImage && !deleted ? (
        <img src={initialImage} className="object-cover w-full " style={{ aspectRatio: "3" }} />
      ) : defaultImage ? (
        <img src={defaultImage} className="object-cover w-full " style={{ aspectRatio: "3" }} />
      ) : (
        <div className="flex items-center justify-center w-full px-6 pt-5 pb-6 mt-1 border-2 border-gray-300 border-dashed rounded-md">
          <div className="flex flex-col items-center space-y-1 text-center">
            <svg
              className="w-12 h-12 mx-auto text-gray-400"
              stroke="currentColor"
              fill="none"
              viewBox="0 0 48 48"
              aria-hidden="true"
            >
              <path
                d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            <div className="flex text-sm text-gray-600">
              <label
                htmlFor="file-upload"
                className="relative font-medium bg-white rounded-md cursor-pointer text-main hover:text-main focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-main"
              >
                <span className="mx-auto text-center">{t("Upload files")}</span>
              </label>
            </div>
            <p className="text-xs text-gray-500">{t("PNG, JPG, GIF up to 10MB")}</p>
          </div>
        </div>
      )}
      {((initialImage && !deleted) || image) && (
        <TrashIcon
          className="absolute p-1 text-white bg-red-500 border-2 border-red-500 rounded-full cursor-pointer hover:border-white top-2 right-2 w-7 h-7"
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            setImage(null);
            setDeleted(true);
          }}
        />
      )}
      <input
        ref={inputRef}
        id="file-upload-cover"
        accept=".png,.jpg,.jpeg,.png,.gif"
        name="file-upload"
        type="file"
        className="sr-only"
        onChange={uploadFile}
      />
    </div>
  );
};

export const InputProfileImage = ({
  image,
  setImage,
  defaultImage,
  initialImage,
  deleted,
  setDeleted,
}: {
  image: File | null;
  setImage: (i: File | null) => void;
  defaultImage?: string;
  initialImage?: string;
  deleted: boolean;
  setDeleted: (b: boolean) => void;
}) => {
  const ref = useRef<HTMLInputElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  const uploadFile = (e: ChangeEvent<HTMLInputElement>) => {
    const im = e.target.files?.[0];
    if (im) handleImage(im);
  };

  // Handling the event of the image input
  const handleImage = async (file: File | null) => {
    if (!file) return;
    //@ts-ignore
    Compress.imageFileResizer(file, 500, 500, "JPEG", 80, 0, setImage, "file");
  };

  return (
    <div
      className="relative flex justify-center w-24 h-24 mt-1 overflow-hidden bg-white border-2 border-gray-300 border-dashed rounded-full cursor-pointer"
      onClick={() => inputRef.current?.click()}
      ref={ref}
    >
      {image ? (
        <img src={URL.createObjectURL(image)} className="object-cover w-full h-full " />
      ) : initialImage && !deleted ? (
        <img src={initialImage} className="object-cover w-full h-full " />
      ) : defaultImage ? (
        <img src={defaultImage} className="object-cover w-full h-full" />
      ) : (
        <div className="px-6 pt-5 pb-6">
          <svg
            className="mx-auto my-auto text-gray-400 h-9 w-9"
            stroke="currentColor"
            fill="none"
            viewBox="0 0 48 48"
            aria-hidden="true"
          >
            <path
              d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
              strokeWidth={2}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </div>
      )}

      <input
        ref={inputRef}
        id="file-upload"
        accept=".png,.jpg,.jpeg,.png,.gif"
        name="file-upload"
        type="file"
        className="sr-only"
        onChange={uploadFile}
      />
    </div>
  );
};
