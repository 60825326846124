// export const ChangePassword = () => <div />;
import z from "zod";
import { ArrowLeftIcon, LockClosedIcon } from "@heroicons/react/solid";
import { zodResolver } from "@hookform/resolvers/zod";
import { Spinner } from "components/Spinner";
import { useMutation } from "hooks/useBridge";
import { useForm } from "hooks/useForm";
import { useGlobalState } from "hooks/useGlobalState";
import { useTranslation } from "react-i18next";
import { API } from "sdk";
import { Link, useNavigate } from "react-router-dom";

export const ChangePassword = () => {
  const { t } = useTranslation("changePassword");

  const [chef] = useGlobalState("chef");
  const navigate = useNavigate();

  const { mutate: updatePassword, isLoading } = useMutation(API.chef.updateMyPassword, {
    onSuccess: (res) => navigate("/"),
  });

  const { register, handleSubmit, errors } = useForm(
    (data: { body: { oldPassword: string; newPassword: string }; confirmPassword: string }) =>
      updatePassword({ body: data.body, headers: { refreshtoken: chef.refreshToken || "" } }),
    {
      resolver: zodResolver(
        z
          .object({ body: API.chef.zodSchemas.updateMyPassword.body, confirmPassword: z.string() })
          .refine((data) => data.body.newPassword === data.confirmPassword, {
            message: "Passwords do not match",
            path: ["passwordConfirmation"],
          })
      ),
    }
  );

  return (
    <div className="flex w-full max-h-screen ">
      <div className="flex items-center justify-center flex-1 min-h-full px-4 py-12 sm:px-6 lg:px-8">
        <div className="w-full max-w-lg p-8 bg-white border border-gray-200 rounded-md">
          <div className="flex flex-col items-start">
            <Link to="/">
              <div className="p-1 border rounded-md">
                <ArrowLeftIcon className="w-6 h-6 text-gray-500" />
              </div>
            </Link>
            <h2 className="mt-4 text-4xl font-extrabold">{t("Change password")}</h2>
          </div>
          <form className="mt-4 " onSubmit={handleSubmit}>
            <div>
              <label htmlFor="password" className="font-semibold">
                {t("Password")}
              </label>
              <input
                id="password"
                type="password"
                autoComplete="email"
                required
                className="relative block w-full px-3 py-2 mt-1 text-gray-900 placeholder-gray-500 border border-gray-300 rounded-md appearance-none focus:outline-none focus:ring-main focus:border-main focus:z-10 sm:text-sm"
                placeholder={t("Password")}
                {...register("body.oldPassword")}
              />
              {errors.body?.oldPassword ? (
                <p className="mt-0.5 text-xs text-red-700 w-full text-right">{t("Password too short")}</p>
              ) : null}
            </div>
            <div className="mt-4">
              <label htmlFor="password" className="font-semibold">
                {t("New password")}
              </label>
              <input
                id="password"
                type="password"
                autoComplete="email"
                required
                className="relative block w-full px-3 py-2 mt-1 text-gray-900 placeholder-gray-500 border border-gray-300 rounded-md appearance-none focus:outline-none focus:ring-main focus:border-main focus:z-10 sm:text-sm"
                placeholder={t("Password")}
                {...register("body.newPassword")}
              />
              {errors.body?.newPassword ? (
                <p className="mt-0.5 text-xs text-red-700 w-full text-right">{t("Password too short")}</p>
              ) : null}
            </div>
            <div className="flex flex-col">
              <label htmlFor="email-address" className="mt-4 font-semibold">
                {t("Confirm password")}
              </label>
              <input
                id="password"
                type="password"
                autoComplete="email"
                required
                className="relative block w-full px-3 py-2 mt-1 text-gray-900 placeholder-gray-500 border border-gray-300 rounded-md appearance-none focus:outline-none focus:ring-main focus:border-main focus:z-10 sm:text-sm"
                placeholder={t("Confirm password")}
                {...register("confirmPassword")}
              />
              {(errors as any)?.passwordConfirmation === "Passwords do not match" ? (
                <p className="mt-0.5 text-xs text-red-700 w-full text-right">{t("Passwords must be the same")}</p>
              ) : null}
            </div>
            <div className="mt-8">
              <button
                type="submit"
                className="relative flex justify-center w-full px-4 py-2 text-sm font-medium text-white border border-transparent rounded-md bg-main group hover:bg-main focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-main"
              >
                <span className="absolute left-0 flex items-center pl-3 insets-y-0">
                  {isLoading ? (
                    <Spinner />
                  ) : (
                    <LockClosedIcon className="w-5 h-5 text-main group-hover:text-mainLight" aria-hidden="true" />
                  )}
                </span>
                {t("Change password")}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
