import type { Identifier, XYCoord } from "dnd-core";
import type { FC } from "react";
import { useRef } from "react";
import { DndProvider, useDrag, useDrop } from "react-dnd";
import update from "immutability-helper";
import { useCallback } from "react";
import { HTML5Backend } from "react-dnd-html5-backend";

type ItemProps<Item> = {
  index: number;
  itemType: string;
  ItemComp: FC<{ item: Item; index: number }>;
  item: Item;
  moveItem: (dragIndex: number, hoverIndex: number) => void;
};

interface DragItem {
  index: number;
  id: string;
  type: string;
}

export const Item = <Item extends { id: string }>({ index, moveItem, itemType, item, ItemComp }: ItemProps<Item>) => {
  const ref = useRef<HTMLDivElement>(null);

  const [{ handlerId }, drop] = useDrop<DragItem, void, { handlerId: Identifier | null }>({
    accept: itemType,
    collect: (monitor) => ({ handlerId: monitor.getHandlerId() }),
    hover: (item: DragItem, monitor) => {
      if (!ref.current) return;
      const dragIndex = item.index;
      const hoverIndex = index;

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) return;

      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect();

      // Get vertical middle
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

      // Determine mouse position
      const clientOffset = monitor.getClientOffset();

      // Get pixels to the top
      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;

      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%

      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) return;

      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) return;

      // Time to actually perform the action
      moveItem(dragIndex, hoverIndex);

      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex;
    },
  });

  const [{ isDragging }, drag] = useDrag({
    type: itemType,
    item: () => ({ item: item.id, index }),
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const opacity = isDragging ? 0 : 1;
  drag(drop(ref));
  return (
    <div ref={ref} style={{ opacity }} data-handler-id={handlerId}>
      <ItemComp item={item} index={index} />
    </div>
  );
};

export const SortableDnD = <ItemType extends { id: string }>({
  items,
  setItems,
  ItemComp,
  itemType,
}: {
  items: ItemType[];
  setItems: (i: (i: ItemType[]) => ItemType[]) => void;
  ItemComp: FC<{ item: ItemType; index: number }>;
  itemType: string;
}) => {
  const moveItem = useCallback((dragIndex: number, hoverIndex: number) => {
    setItems((prevItems: ItemType[]) =>
      update(prevItems, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, prevItems[dragIndex] as ItemType],
        ],
      })
    );
  }, []);

  const renderItem = useCallback((item: ItemType, index: number) => {
    return (
      <Item
        key={item.id}
        index={index}
        item={item}
        moveItem={moveItem}
        itemType={itemType}
        ItemComp={ItemComp as any}
      />
    );
  }, []);

  return <DndProvider backend={HTML5Backend}>{items.map((item, i) => renderItem(item, i))}</DndProvider>;
};
