import { useEffect } from "react";
import { useGlobalState } from "./useGlobalState";
type LocalStorageType = {
  accessToken: string;
  refreshToken: string;
};

export const getItem = <Key extends keyof LocalStorageType>(key: Key) =>
  localStorage.getItem(key) as LocalStorageType[Key];
export const setItem = <Key extends keyof LocalStorageType>(key: Key, value: LocalStorageType[Key]) =>
  localStorage.setItem(key, value);
export const removeItem = <Key extends keyof LocalStorageType>(key: Key) => localStorage.removeItem(key);

export const useLocalStorageTokens = () => {
  const [chef, setChef] = useGlobalState("chef");
  useEffect(() => {
    const accessToken = getItem("accessToken");
    const refreshToken = getItem("refreshToken");
    if (accessToken && refreshToken) setChef({ ...chef, accessToken, refreshToken, isAuthenticated: true });
    else setChef({ isAuthenticated: false, accessToken: "", refreshToken: "", me: null });
  }, []);
};

export const storeTokens = ({ refreshToken, accessToken }: { refreshToken: string; accessToken: string }) => {
  setItem("accessToken", accessToken);
  setItem("refreshToken", refreshToken);
};

export const removeTokens = () => {
  removeItem("accessToken");
  removeItem("refreshToken");
};
