import { useEffect, useRef, useState } from "react";

interface CodeInputProps {
  setValue: (v: string) => void;
  n: number;
  onComplete?: (code: string) => void;
  reset?: boolean;
}

export const CodeInput = ({ setValue, n, onComplete, reset }: CodeInputProps) => {
  const [array, setArray] = useState<any[]>(Array(n).fill(undefined));
  const inputRefs = useRef<any>(Array(n).fill(null));

  const setNumber = (i: number, v: any = "") => {
    if (isNaN(v) && v) return;
    const m = Math.min(i + v.length, n - 1);
    if (!v) array[i] = "";
    Array.from(v).forEach((l, p) => i + p < n && (array[i + p] = l || ""));
    if (array[m - 1]) inputRefs.current[m]?.focus?.();
    setArray([...array]);
    setValue(array.join(""));
    if (array.join("").length === n) onComplete?.(array.join(""));
  };

  const handleKeyPress = (i: number, e: any) => {
    switch (e.key) {
      case "Backspace":
      case "ArrowLeft":
        if (i > 0) inputRefs.current[i - 1].focus();
        break;
      case "ArrowRight":
        if (i < n - 1) inputRefs.current[i + 1].focus();
        break;
    }
  };

  useEffect(() => {
    setArray(array.map(() => undefined));
    setValue("");
  }, [reset]);

  return (
    <div className="grid grid-cols-6 gap-x-3">
      {array.map((_, i) => (
        <input
          onFocus={(e) => e.target.select()}
          className="h-20 col-span-1 text-3xl font-black text-center border rounded-md outline-none bg-gray-50 focus:border-2"
          value={array[i] || ""}
          onChange={(e) => setNumber(i, e.target.value)}
          onKeyUp={(e) => handleKeyPress(i, e)}
          ref={(el) => (inputRefs.current[i] = el)}
        />
      ))}
    </div>
  );
};
