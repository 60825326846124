import { CollectionIcon, HomeIcon, InboxIcon } from "@heroicons/react/solid";
// import Logo from "components/shared/Logo";
import { isError, useQuery, useAuth } from "hooks/useBridge";
import { useGlobalState } from "hooks/useGlobalState";
import { useRedirectIfNotAuthenticated } from "hooks/useSession";
import { useTranslation } from "react-i18next";
import { NavLink, Outlet } from "react-router-dom";
import { API } from "sdk";
import logo from "assets/maes.svg";
import { NewHeader } from "components/shared/Header";

export const Dashboard = () => {
  const [chef, setChef] = useGlobalState("chef");
  useRedirectIfNotAuthenticated();

  const getMe = useAuth(API.chef.getMe);

  useQuery(["getMe", chef.isAuthenticated], () => getMe({}), {
    enabled: !!chef.isAuthenticated,
    onSuccess: (res) => {
      if (isError(res)) return;
      setChef({ ...chef, isAuthenticated: true, me: res });
    },
  });

  return (
    <div className="flex flex-row h-screen">
      {/* <SideBar /> */}
      <div className="flex flex-col flex-1 overflow-scroll bg-gray-50">
        <NewHeader />
        <Outlet />
      </div>
    </div>
  );
};

export const Header = () => {
  const [chef] = useGlobalState("chef");
  return (
    <div className="sticky top-0 z-20 flex items-center flex-shrink-0 w-full h-16 px-4 bg-white border-b border-gray-200 sm:px-8">
      <img src={logo} className="w-24 h-10" />
      <div className="ml-auto">
        <p className="font-medium">fr</p>
      </div>
      {/* {chef.me?.profileImage ? <img src={chef.me?.profileImage} className="w-16 h-16 bg-blue-50" /> : null} */}
      <img src={chef.me?.profileImage} className="object-cover w-8 h-8 ml-4 rounded-full" />
    </div>
  );
};
