import { useMutation } from "hooks/useBridge";
import { useForm } from "hooks/useForm";
import { useGlobalState } from "hooks/useGlobalState";
import { storeTokens } from "hooks/useLocalStorage";
import { FieldErrors, UseFormRegister } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import TermOfServices from "../../public/TermsOfService.pdf";
import z from "zod";
import { useTranslation } from "react-i18next";
import { API } from "sdk";
import { APIType } from "types/api";
import { useLocation, useNavigate } from "react-router-dom";

export const Signup = () => {
  const { t } = useTranslation("signup");
  const navigate = useNavigate();
  const location = useLocation();
  const params = new URLSearchParams(location.search);

  const [_, setChef] = useGlobalState("chef");

  const confirmPasswordAndSignup = (data: APIType["chef"]["signup"]["Data"]) => {
    return API.chef.signup(data);
  };

  const { mutate, error } = useMutation(confirmPasswordAndSignup, {
    onSuccess: (res) => {
      storeTokens({ refreshToken: res.refreshToken, accessToken: res.accessToken });
      setChef((c) => ({ ...c, refreshToken: res.refreshToken, accessToken: res.accessToken, isAuthenticated: true }));
      navigate(params.get("from") || "/");
    },
  });

  const signup = (data: APIType["chef"]["signup"]["Data"] & { confirmPassword: string; acceptTerms: boolean }) => {
    return mutate({ body: data.body });
  };

  const { register, handleSubmit, errors, watch, control } = useForm(signup, {
    resolver: zodResolver(
      z
        .object({ body: API.chef.zodSchemas.signup.body, confirmPassword: z.string().min(6), acceptTerms: z.boolean() })
        .refine((data) => data.body.password === data.confirmPassword, {
          message: "Passwords do not match",
          path: ["passwordConfirmation"],
        })
        .refine((data) => data.acceptTerms, {
          message: "You need to accept terms and conditions before signing up",
          path: ["acceptTerms"],
        })
    ),
  });

  console.log(watch());
  console.log(errors);

  return (
    <div className="flex w-full">
      <div className="flex-1 hidden min-h-screen md:flex">
        <img src={require("assets/signin.jpg")} className="fixed object-cover w-1/2 h-screen max-h-screen " />
      </div>
      <div className="flex items-center justify-center flex-1 px-4 overflow-auto sm:px-6 lg:px-8">
        <div className="w-full max-w-md space-y-8">
          <div className="mt-24">
            <h2 className="mt-6 text-4xl font-extrabold">{t("Become a chef")}</h2>
            <p className="mt-4 text-sm text-gray-700">{t("Signup description")}</p>
          </div>
          <form className="" onSubmit={handleSubmit}>
            {error && error.error.name === "Already exists" && (
              <p className="p-4 mb-2 text-sm text-red-500 rounded-md bg-red-50">{t(error.error.name)}</p>
            )}
            <Form register={register} error={error} errors={errors} />
          </form>
          <div className="flex flex-col items-center text-sm">
            <div className="flex items-center w-full gap-4">
              <div className="flex flex-1 border-t border-gray-200" />
              <p>or</p>
              <div className="flex flex-1 border-t border-gray-200" />
            </div>
            <a href="/signin" className="mt-4 font-medium text-main hover:text-main">
              {t("I already have an account")}
            </a>
          </div>
          <div className="mt-24" />
        </div>
      </div>
    </div>
  );
};

interface FormProps {
  register: UseFormRegister<APIType["chef"]["signup"]["Data"] & { confirmPassword: string; acceptTerms: boolean }>;
  error:
    | APIType["chef"]["signup"]["ErrorsReturn"]
    | { error: { name: "Invalid confirm password"; status: 0; data: {} } }
    | null;
  errors: FieldErrors<APIType["chef"]["signup"]["Data"] & { confirmPassword: string; acceptTerms: string }>;
}

const Form = ({ register, error, errors }: FormProps) => {
  const { t } = useTranslation("signup");
  return (
    <>
      <div className="-space-y-px rounded-md">
        <div className="flex gap-4">
          <div className="flex flex-col flex-1">
            <label htmlFor="first-name" className="font-semibold">
              {t("First name")}
            </label>
            <input
              id="first-name"
              type="text"
              autoComplete="first-name"
              required
              className="relative block w-full px-3 py-2 mt-1 mb-4 text-gray-900 placeholder-gray-500 border border-gray-300 rounded-md appearance-none focus:outline-none focus:ring-main focus:border-main focus:z-10 sm:text-sm"
              placeholder={t("First name")}
              {...register("body.firstName")}
            />
          </div>
          <div className="flex flex-col flex-1">
            <label htmlFor="last-name" className="font-semibold">
              {t("Last name")}
            </label>
            <input
              id="last-name"
              type="text"
              autoComplete="last-name"
              required
              className="relative block w-full px-3 py-2 mt-1 mb-4 text-gray-900 placeholder-gray-500 border border-gray-300 rounded-md appearance-none focus:outline-none focus:ring-main focus:border-main focus:z-10 sm:text-sm"
              placeholder={t("Last name")}
              {...register("body.lastName")}
            />
          </div>
        </div>
        <div>
          <label htmlFor="phone" className="font-semibold">
            {t("Phone")}
          </label>
          <input
            id="phone"
            type="phone"
            autoComplete="phone"
            required
            className="relative block w-full px-3 py-2 mt-1 mb-4 text-gray-900 placeholder-gray-500 border border-gray-300 rounded-md appearance-none focus:outline-none focus:ring-main focus:border-main focus:z-10 sm:text-sm"
            placeholder={t("Phone")}
            {...register("body.phone")}
          />
        </div>
        <div>
          <label htmlFor="email-address" className="font-semibold">
            {t("Email address")}
          </label>
          <input
            id="email-address"
            type="email"
            autoComplete="email"
            required
            className="relative block w-full px-3 py-2 mt-1 mb-4 text-gray-900 placeholder-gray-500 border border-gray-300 rounded-md appearance-none focus:outline-none focus:ring-main focus:border-main focus:z-10 sm:text-sm"
            placeholder={t("Email address")}
            {...register("body.email")}
          />
        </div>
        <div>
          <label htmlFor="password" className="font-semibold">
            {t("Password")}
          </label>
          <input
            id="password"
            type="password"
            autoComplete="email"
            required
            className="relative block w-full px-3 py-2 mt-1 text-gray-900 placeholder-gray-500 border border-gray-300 rounded-md appearance-none focus:outline-none focus:ring-main focus:border-main focus:z-10 sm:text-sm"
            placeholder={t("Password")}
            {...register("body.password")}
          />
          {errors.body?.password ? (
            <p className="mt-0.5 text-xs text-red-700 w-full text-right">{t("Password too short")}</p>
          ) : null}
        </div>
        <div className="flex flex-col">
          <label htmlFor="email-address" className="mt-4 font-semibold">
            {t("Confirm password")}
          </label>
          <input
            id="password"
            type="password"
            autoComplete="email"
            required
            className="relative block w-full px-3 py-2 mt-1 text-gray-900 placeholder-gray-500 border border-gray-300 rounded-md appearance-none focus:outline-none focus:ring-main focus:border-main focus:z-10 sm:text-sm"
            placeholder={t("Confirm password")}
            {...register("confirmPassword")}
          />
          {error?.error.name === "Invalid confirm password" ? (
            <p className="mt-0.5 text-xs text-red-700 w-full text-right">{t("Passwords must be the same")}</p>
          ) : null}
        </div>
        <div className="flex flex-col mb-4">
          <label htmlFor="languages" className="mt-4 font-semibold">
            {t("Select a language")}
          </label>
          <select
            id="languages"
            autoComplete="languages"
            className="block w-32 px-2 py-1 mt-1 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-main focus:border-main sm:text-sm"
            {...register("body.language")}
          >
            <option value={"english"}>English</option>
            <option value={"portuguese"}>Portuguese</option>
          </select>
        </div>
        <div className="flex items-start pt-4 cursor-pointer">
          <div className="flex items-center h-5">
            <input
              id="terms"
              type="checkbox"
              className="w-4 h-4 border-gray-300 rounded cursor-pointer text-main focus:ring-main"
              {...register("acceptTerms")}
            />
          </div>
          <label htmlFor="terms" className="cursor-pointer">
            <div className="ml-3 text-sm">
              <p className="">
                {t("Accept")}{" "}
                <a href={TermOfServices} target="_blank" className="font-semibold text-main">
                  {t("privacy policy")}
                </a>{" "}
                {t("and")}{" "}
                <a href={TermOfServices} target="_blank" className="font-semibold text-main">
                  {t("terms and conditions")}
                </a>
              </p>
            </div>
          </label>
        </div>
        {errors.acceptTerms ? (
          <p className="w-full pt-1 text-xs text-left text-red-700">{t("Please accept terms and conditions")}</p>
        ) : null}
      </div>

      <div>
        <button
          type="submit"
          className="relative flex justify-center w-full px-4 py-2 mt-4 text-sm font-medium text-white border border-transparent rounded-md bg-main group hover:bg-main focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-main"
        >
          {t("Become a chef")}
        </button>
      </div>
    </>
  );
};
