import z from "zod";
import { XIcon } from "@heroicons/react/outline";
import { Overlay } from "components/utils/Overlay";
import { useMutation, useAuth } from "hooks/useBridge";
import { useForm } from "hooks/useForm";
import { ChangeEvent, Dispatch, SetStateAction, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useParams } from "react-router-dom";
import { API } from "sdk";
import { zodResolver } from "@hookform/resolvers/zod";
import { ALLERGENES } from "data/Allergenes";
import { Control, useController } from "react-hook-form";
import { MultipleSelect, Select } from "components/form/Select";
import { CATEGORIES } from "data/Categories";
import { APIType } from "types/api";
import { useGlobalState } from "hooks/useGlobalState";
import { ArrowLeftIcon } from "@heroicons/react/solid";
import { extract } from "utils/Object";
import { SuccessToast } from "components/utils/Toast";
import { toast } from "react-hot-toast";
import { DIETS } from "data/Diets";
import { DISHTYPES } from "data/DishTypes";
import { Spinner } from "components/Spinner";

type UpdateDishDataType = APIType["dish"]["updateByChef"]["NoHeadersReturn"];
type DishType = APIType["chef"]["getMe"]["NoErrorsReturn"]["dishes"][number];
type DishByChefType = APIType["chef"]["getMe"]["NoErrorsReturn"]["dishes"][number]["byChef"];

type Keys = (keyof DishByChefType)[];
export const MyDish = () => {
  const { id } = useParams();
  const [chef] = useGlobalState("chef");
  const initialDish = chef.me?.dishes.filter((d) => d._id === id)?.[0];
  if (!initialDish) return <div />;
  return <MyDish2 initialDish={initialDish} />;
};

const Header = ({ isLoading, isLoadingImage }: { isLoading: boolean; isLoadingImage: boolean }) => {
  const { t } = useTranslation("myDish");
  return (
    <div className="sticky top-0 z-10 flex items-center w-full h-16 px-4 bg-white border-b-2 border-gray-200 sm:px-8">
      <Link to="/">
        <div className="p-1 border rounded-md">
          <ArrowLeftIcon className="w-6 h-6 text-gray-500" />
        </div>
      </Link>
      <div className="flex items-center ml-auto">
        <Link
          to="/"
          className="relative flex justify-center px-4 py-2 ml-auto text-sm font-medium border border-gray-500 rounded-md text-main group hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-main"
        >
          {t("Cancel")}
        </Link>
        {!(isLoading || isLoadingImage) ? (
          <button
            type="submit"
            form="mainForm"
            className="relative flex justify-center px-4 py-2 ml-4 text-sm font-medium text-white border border-transparent rounded-md bg-main group hover:bg-main focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-main"
          >
            {t("Save")}
          </button>
        ) : (
          <button className="relative flex justify-center px-4 py-2 ml-4 text-sm font-medium text-white border border-transparent rounded-md bg-mainLight group hover:bg-mainLight focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-main">
            <span className="flex items-center insets-y-0">
              <Spinner />
            </span>
            {t("Save")}
          </button>
        )}
      </div>
    </div>
  );
};

export const MyDish2 = ({ initialDish }: { initialDish: DishType }) => {
  const { t } = useTranslation("myDish");
  const [addedImages, setAddedImages] = useState<File[]>([]);
  const keys: Keys = ["allergens", "category", "description", "diet", "images", "name", "price", "ingredients", "type"];
  const defaultValues = {
    body: { ...extract(initialDish.byChef, keys), price: (initialDish.byChef.price || 0) / 100 },
    query: { dishId: initialDish._id || undefined },
  };

  const tokenizedUpdateDish = useAuth(API.dish.updateByChef);
  const tokenizedUpdateImagesByChef = useAuth(API.dish.updateImagesByChef);

  const { mutate: updateDish, isLoading } = useMutation(tokenizedUpdateDish, {
    onSuccess: (res) => {
      reset({
        body: { ...extract(res.byChef, keys), price: (res.byChef.price || 0) / 100 },
        query: { dishId: res._id },
      });
      toast.custom((toast) => <SuccessToast t={toast} text={t("Successfully modified")} />);
      updateImages({ dishId: res._id });
    },
  });

  const { mutate: updateImagesByChef, isLoading: isLoadingImage } = useMutation(tokenizedUpdateImagesByChef, {
    onSuccess: (res) => {
      console.log(res);
    },
  });

  const updateImages = ({ dishId }: { dishId: string }) => {
    updateImagesByChef({
      files: addedImages.reduce((a, im, i) => ({ ...a, [`file${i}`]: im }), {}),
      query: { dishId },
    });
  };

  const { register, handleSubmit, errors, control, reset, watch } = useForm(updateDish, {
    defaultValues: defaultValues,
    resolver: (data, context, options) => {
      const updatedData = { ...data, body: { ...data.body, price: parseFloat((data.body.price || 0) as any) * 100 } };
      return zodResolver(
        z.object({ body: API.dish.zodSchemas.updateByChef.body, query: z.object({ dishId: z.string() }) })
      )(updatedData, context, options);
    },
  });

  useEffect(() => reset(), [initialDish?._id]);

  console.log("watch", watch());

  console.log(errors);

  return (
    <div className="relative w-full max-h-screen overflow-scroll">
      <Header isLoading={isLoading} isLoadingImage={isLoadingImage} />
      <form className="" onSubmit={handleSubmit} id="mainForm" />
      <div className="items-start gap-8 px-4 py-4 mx-auto sm:px-8 sm:grid sm:grid-cols-12">
        <div className="col-span-12 md:col-span-7 rounded-md p-4 bg-white border border-gray*200">
          <div className="flex">
            <p className="font-bold">{t("Name")}</p>
          </div>
          <input
            id="name"
            className="relative block w-full px-3 py-2 mt-2 text-gray-900 placeholder-gray-500 border border-gray-300 rounded-md appearance-none focus:outline-none focus:ring-main focus:border-main focus:z-10 sm:text-sm"
            placeholder={t("Name placeholder")}
            {...register("body.name")}
            form="mainForm"
          />
          <div className="flex mt-4">
            <p className="font-bold">{t("DescriptionDish")}</p>
          </div>
          <p className="text-sm text-gray-500 ">{t("DescriptionOfDescriptionDish")}</p>
          <textarea
            id="description"
            rows={6}
            className="block w-full mt-2 border border-gray-300 rounded-md focus:ring-main focus:border-main sm:text-sm"
            placeholder={t("DescriptionDish")}
            {...register("body.description")}
            form="mainForm"
          />
          <div className="">
            <div className="flex mt-4">
              <p className="font-bold">{t("Ingredients")}</p>
            </div>
            <p className="text-sm text-gray-500 ">{t("IngredientsDescription")}</p>
            <AddIngredients control={control} />
          </div>{" "}
          <AddAllergenes control={control} />
        </div>
        <div className="flex flex-col col-span-12 gap-4 md:col-span-5">
          <div className="p-4 bg-white border border-gray-200 rounded-md">
            <div className="flex flex-col">
              <p className="font-bold">{t("Recommended price")}</p>
              <p className="text-sm text-gray-500 ">{t("SetAPriceForYourDish")}</p>
            </div>
            <input
              id="price"
              type="number"
              step="0.01"
              className="relative block w-full px-3 py-2 mt-2 text-gray-900 placeholder-gray-500 border border-gray-300 rounded-md appearance-none focus:outline-none focus:ring-main focus:border-main focus:z-10 sm:text-sm"
              placeholder={t("Recommended price")}
              {...register("body.price")}
              form="mainForm"
            />
          </div>
          <div className="p-4 bg-white border border-gray-200 rounded-md">
            <div className="flex">
              <p className="font-bold">{t("Category")}</p>
            </div>
            <CategorySelect control={control} />
            {errors.body?.category && <p className="text-red">{t("Please select a category")}</p>}
          </div>
          <div className="p-4 bg-white border border-gray-200 rounded-md">
            <div className="flex">
              <p className="font-bold">{t("Diet")}</p>
            </div>
            <DietSelect control={control} />
            {errors.body?.diet && <p className="text-red">{t("Please select a diet")}</p>}
          </div>
          <div className="p-4 bg-white border border-gray-200 rounded-md">
            <div className="flex">
              <p className="font-bold">{t("Type of dish")}</p>
            </div>
            <DishTypeSelect control={control} />
            {errors.body?.type && <p className="mt-1 text-sm text-red-500">{t("Please select a type")}</p>}
          </div>
          <Images addedImages={addedImages} setAddedImages={setAddedImages} control={control} />
        </div>
      </div>
      <div className=""></div>
    </div>
  );
};

interface ImagesProps {
  addedImages: File[];
  setAddedImages: Dispatch<SetStateAction<File[]>>;
  control: Control<UpdateDishDataType>;
}

const Images = ({ addedImages, setAddedImages, control }: ImagesProps) => {
  const { t } = useTranslation("myDish");

  const uploadFile = (e: ChangeEvent<HTMLInputElement>) => {
    const im = e.target.files?.[0];
    if (im && !addedImages.some((i) => i.name === im.name && i.lastModified === im.lastModified))
      setAddedImages([...addedImages, im]);
  };

  const { field } = useController({ name: "body.images", control });
  const { value: images, onChange: setImages } = field;

  const delImage = (i: number) => setImages(images?.filter((_, j) => i != j));
  const delAddedImage = (i: number) => setAddedImages((images) => images.filter((_, j) => i !== j));

  return (
    <div className="p-4 bg-white border border-gray-200 rounded-md">
      <div className="flex mb-4">
        <p className="font-bold">{t("Upload your pictures")}</p>
      </div>

      <div className="flex justify-center px-6 pt-5 pb-6 mt-1 border-2 border-gray-300 border-dashed rounded-md">
        <div className="space-y-1 text-center">
          <svg
            className="w-12 h-12 mx-auto text-gray-400"
            stroke="currentColor"
            fill="none"
            viewBox="0 0 48 48"
            aria-hidden="true"
          >
            <path
              d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
              strokeWidth={2}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          <div className="flex text-sm text-gray-600">
            <label
              htmlFor="file-upload"
              className="relative items-center w-full font-medium text-center bg-white rounded-md cursor-pointer text-main hover:text-main focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-main"
            >
              <span>{t("Upload files")}</span>
            </label>
            <input
              id="file-upload"
              accept=".png,.jpg,.jpeg,.png,.gif"
              name="file-upload"
              type="file"
              className="sr-only"
              onChange={uploadFile}
            />
          </div>
          <p className="text-xs text-gray-500">{t("PNG, JPG, GIF up to 10MB")}</p>
        </div>
      </div>
      <div className="flex flex-wrap gap-4 mt-4">
        {images?.map((i, index) => (
          <ImageSelect image={i} delImage={delImage} i={index} />
        ))}
        {addedImages.map((i, index) => (
          <ImageSelect image={URL.createObjectURL(i)} delImage={delAddedImage} i={index} />
        ))}
      </div>
    </div>
  );
};

const ImageSelect = ({ image, i, delImage }: { image: string; i: number; delImage: (i: number) => void }) => {
  const [open, setOpen] = useState(false);
  return (
    <div className="relative">
      <img src={image} className="object-cover w-24 h-24 rounded-md cursor-pointer" onClick={() => setOpen(true)} />
      <Overlay open={open} setOpen={setOpen}>
        <img src={image} className="object-cover w-full" />
      </Overlay>
      <XIcon
        className="absolute w-6 p-1 text-gray-700 border-2 border-gray-200 rounded-full cursor-pointer hover:bg-gray-100 -top-2.5 -right-2.5 bg-gray-50"
        onClick={() => delImage(i)}
      />
    </div>
  );
};

const DishTypeSelect = ({ control }: { control: Control<UpdateDishDataType> }) => {
  const { t: tDishType } = useTranslation("dishTypes");
  const { t } = useTranslation("addADish");
  const { field } = useController({ name: "body.type", control });
  const { value: dishType, onChange: setDishType } = field;

  return (
    <Select
      options={DISHTYPES.map((c) => ({ value: c, label: tDishType(c) } as const))}
      selected={dishType}
      setSelected={setDishType}
      placeholder={t("Select a dish type")}
    />
  );
};

const DietSelect = ({ control }: { control: Control<UpdateDishDataType> }) => {
  const { t: tDiet } = useTranslation("diets");
  const { t } = useTranslation("addADish");
  const { field } = useController({ name: "body.diet", control });
  const { value: diet, onChange: setDiet } = field;

  return (
    <MultipleSelect
      options={DIETS.map((c) => ({ value: c, label: tDiet(c) } as const))}
      selected={diet}
      setSelected={setDiet}
      placeholder={t("Select a diet")}
    />
  );
};

const CategorySelect = ({ control }: { control: Control<UpdateDishDataType> }) => {
  const { t: tCategory } = useTranslation("categories");
  const { t } = useTranslation("myDish");
  const { field } = useController({ name: "body.category", control });
  const { value: category, onChange: setCategory } = field;

  return (
    <Select
      options={CATEGORIES.map((c) => ({ value: c, label: tCategory(c) } as const))}
      selected={category}
      setSelected={setCategory}
      placeholder={t("Select a category")}
    />
  );
};

interface AllergenesModalProps {
  open: boolean;
  setOpen: (o: boolean) => void;
  allergenes: typeof ALLERGENES[number][];
  setAllergenes: (a: typeof ALLERGENES[number][]) => void;
}

const AllergenesModal = ({ open, setOpen, allergenes, setAllergenes }: AllergenesModalProps) => {
  const { t } = useTranslation("myDish");
  const [localAllergenes, setLocalAllergenes] = useState<typeof ALLERGENES[number][]>([]);

  const toggleAllergenes = (allergene: typeof ALLERGENES[number]) =>
    !localAllergenes.includes(allergene)
      ? setLocalAllergenes([allergene, ...localAllergenes])
      : setLocalAllergenes(localAllergenes.filter((a) => a !== allergene));

  useEffect(() => {
    setLocalAllergenes(allergenes);
  }, [allergenes]);

  return (
    <Overlay open={open} setOpen={setOpen}>
      <div className="px-4 pt-5 pb-4 bg-white sm:p-6 sm:pb-4">
        <div className="w-full">
          <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
            <h3 className="text-lg font-medium leading-6 text-gray-900" id="modal-title">
              {t("Select the allergenes")}
            </h3>
            <div className="grid grid-cols-2 mt-4">
              {ALLERGENES.map((allergene, i) => (
                <div
                  className="flex items-center col-span-1 cursor-pointer"
                  onClick={() => toggleAllergenes(allergene)}
                  key={i}
                >
                  <input
                    id="check"
                    readOnly={true}
                    name="check"
                    checked={localAllergenes.includes(allergene)}
                    type="checkbox"
                    className="w-4 h-4 border-gray-300 rounded text-main focus:ring-main"
                  />
                  <p className="ml-3">{allergene}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <div className="px-4 py-3 bg-gray-50 sm:px-6 sm:flex sm:flex-row-reverse">
        <form
          id="allegenesForm"
          onSubmit={(e) => {
            e.preventDefault();
            setAllergenes(localAllergenes);
            setOpen(false);
          }}
        />
        <button
          type="submit"
          form="allegenesForm"
          className="inline-flex justify-center w-full px-4 py-2 text-base font-medium text-white border border-transparent rounded-md bg-main hover:bg-main focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-main sm:ml-3 sm:w-auto sm:text-sm"
        >
          {t("Save")}
        </button>
        <button
          type="button"
          onClick={() => {
            setOpen(false);
            setAllergenes(allergenes);
          }}
          className="inline-flex justify-center w-full px-4 py-2 mt-3 text-base font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-main sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
        >
          {t("Cancel")}
        </button>
      </div>
    </Overlay>
  );
};

const AddAllergenes = ({ control }: { control: Control<UpdateDishDataType> }) => {
  const [openAllergenes, setOpenAllergenes] = useState(false);
  const { t } = useTranslation("myDish");

  const { field } = useController({ name: "body.allergens", control });
  const { onChange: setAllergenes, value: allergenes } = field;

  return (
    <div>
      <AllergenesModal
        open={openAllergenes}
        setOpen={setOpenAllergenes}
        allergenes={allergenes || []}
        setAllergenes={setAllergenes}
      />
      <div className="flex items-center mt-4">
        <p className="font-bold">{t("Allergenes")}</p>
        <button
          type="button"
          onClick={() => setOpenAllergenes(true)}
          className="inline-flex justify-center px-4 py-2 ml-auto text-base font-medium text-white border border-transparent rounded-md bg-main hover:bg-main focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-main sm:w-auto sm:text-sm"
        >
          {t("Select")}
        </button>
      </div>
      <div className="flex flex-wrap gap-2 mt-2">
        {allergenes?.map((allergene, i) => (
          <div className="flex items-center px-2 py-1 border border-gray-200 rounded-md bg-gray-50" key={allergene}>
            <p className="flex text-sm text-gray-700">{allergene}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

const AddIngredients = ({ control }: { control: Control<UpdateDishDataType> }) => {
  const { t } = useTranslation("myDish");
  // const [ingredients, setIngredients] = useState<string[]>([]);

  const { field } = useController({ name: "body.ingredients", control });
  const { onChange: setIngredients, value: ingredients } = field;

  const submit = ({ ingredient }: { ingredient: string }) => {
    setIngredients([...(ingredients || []), ingredient]);
    reset();
  };

  const { register, handleSubmit, reset } = useForm(submit);

  return (
    <form onSubmit={handleSubmit}>
      <div className="flex items-center mt-2">
        <input
          id="ingredient"
          type="text"
          required
          className="relative block w-full px-3 py-2 text-gray-900 placeholder-gray-500 border border-gray-300 rounded-md appearance-none focus:outline-none focus:ring-main focus:border-main focus:z-10 sm:text-sm"
          placeholder={t("Ingredient placeholder")}
          {...register("ingredient")}
        />
        <button
          type="submit"
          className="relative flex justify-center px-4 py-2 ml-4 text-sm font-medium text-white border border-transparent rounded-md bg-main group hover:bg-main focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-main"
        >
          {t("AddIngredient")}
        </button>
      </div>
      <div className="flex flex-wrap gap-2 mt-4">
        {ingredients?.map((ingredient, i) => (
          <div className="flex items-center px-2 py-1 border border-gray-200 rounded-md bg-gray-50" key={i}>
            <p className="flex text-sm text-gray-700">{ingredient}</p>
            <XIcon
              className="w-4 p-0.5 hover:bg-gray-100 ml-1 cursor-pointer"
              onClick={() => setIngredients(ingredients.filter((_, j) => i !== j))}
            />
          </div>
        ))}
      </div>
    </form>
  );
};
