import { useGlobalState } from "hooks/useGlobalState";
import { Fragment } from "react";
import { Disclosure, Menu, Transition } from "@headlessui/react";
import { BellIcon, MenuIcon, XIcon } from "@heroicons/react/outline";
import logo from "assets/logo.png";
import { useMutation } from "react-query";
import { useTranslation } from "react-i18next";
import { API } from "sdk";
import { removeTokens } from "hooks/useLocalStorage";
import defaultChef from "assets/defaultChef.png";

const navigation: { name: string; href: string; current: boolean }[] = [];

const classNames = (...classes: string[]) => {
  return classes.filter(Boolean).join(" ");
};

export const NewHeader = () => {
  const { t } = useTranslation("common");
  const [chef, setChef] = useGlobalState("chef");
  const { mutate: logout } = useMutation(API.chef.logout, {
    onSuccess: () => {
      setChef({ isAuthenticated: false, refreshToken: null, accessToken: null, me: null });
      removeTokens();
    },
    onError: () => {
      setChef({ isAuthenticated: false, refreshToken: null, accessToken: null, me: null });
      removeTokens();
    },
  });

  return (
    <>
      <Disclosure as="nav" className="z-50 h-16 bg-white border-b border-gray-200">
        {({ open }) => (
          <>
            <div className="px-8 mx-auto bg-white">
              <div className="flex items-center justify-between h-16">
                <div className="flex items-center">
                  <div className="flex-shrink-0">
                    <img src={logo} className="object-contain h-10" />
                  </div>
                  <div className="hidden md:block">
                    <div className="flex items-baseline ml-10 space-x-4">
                      {navigation.map((item) => (
                        <a
                          key={item.name}
                          href={item.href}
                          className={classNames(
                            item.current ? "bg-mainDark text-white" : "text-gray-300 hover:bg-main hover:text-white",
                            "px-3 py-2 rounded-md text-sm font-medium"
                          )}
                          aria-current={item.current ? "page" : undefined}
                        >
                          {item.name}
                        </a>
                      ))}
                    </div>
                  </div>
                </div>
                <div className="hidden md:block">
                  <div className="flex items-center ml-4 md:ml-6">
                    {/* Profile dropdown */}
                    <Menu as="div" className="relative ml-3">
                      <div>
                        <Menu.Button className="flex items-center max-w-xs text-sm rounded-full bg-mainDark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-mainDark focus:ring-white">
                          <span className="sr-only">Open user menu</span>
                          {chef.me?.profileImage ? (
                            <img className="object-cover w-8 h-8 rounded-full" src={chef.me?.profileImage} alt="" />
                          ) : (
                            <img className="object-cover w-8 h-8 rounded-full" src={defaultChef} alt="" />
                          )}
                        </Menu.Button>
                      </div>
                      <Transition
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                      >
                        <Menu.Items className="absolute right-0 w-48 py-1 mt-2 origin-top-right bg-white rounded-md shadow-lg z-100 ring-1 ring-black ring-opacity-5 focus:outline-none">
                          <Menu.Item key="changePassword">
                            {({ active }) => (
                              <a
                                href="/change-password"
                                className={classNames(active ? "bg-gray-100" : "", "block px-4 py-2 text-sm text-main")}
                              >
                                {t("Change password")}
                              </a>
                            )}
                          </Menu.Item>
                          <Menu.Item key="logout">
                            {({ active }) => (
                              <div
                                onClick={() =>
                                  logout({ body: { all: false }, headers: { refreshtoken: chef.refreshToken || "" } })
                                }
                                className={classNames(
                                  active ? "bg-gray-100" : "",
                                  "cursor-pointer block px-4 py-2 text-sm text-main"
                                )}
                              >
                                {t("Logout")}
                              </div>
                            )}
                          </Menu.Item>
                        </Menu.Items>
                      </Transition>
                    </Menu>
                  </div>
                </div>
                {/* Mobile menu button */}
                <div className="flex -mr-2 bg-white md:hidden">
                  <Disclosure.Button className="inline-flex items-center justify-center p-2 text-gray-200 rounded-md bg-mainDark hover:text-white hover:bg-main focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-mainDark focus:ring-white">
                    <span className="sr-only">Open main menu</span>
                    {open ? (
                      <XIcon className="block w-6 h-6" aria-hidden="true" />
                    ) : (
                      <MenuIcon className="block w-6 h-6" aria-hidden="true" />
                    )}
                  </Disclosure.Button>
                </div>
              </div>
            </div>

            <Disclosure.Panel className="bg-white md:hidden">
              <div className="pt-1 pb-1 border-t border-b border-gray-200">
                <div className="px-2">
                  <Disclosure.Button
                    key="changePassword"
                    as="a"
                    href="/change-password"
                    className="block px-3 py-2 text-base font-medium rounded-md text-main hover:text-white hover:bg-main"
                  >
                    {t("Change password")}
                  </Disclosure.Button>
                  <Disclosure.Button
                    key="logout"
                    as="a"
                    onClick={() => logout({ body: { all: false }, headers: { refreshtoken: chef.refreshToken || "" } })}
                    className="block px-3 py-2 text-base font-medium rounded-md text-main hover:text-white hover:bg-main"
                  >
                    {t("Logout")}
                  </Disclosure.Button>
                </div>
              </div>
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>
    </>
  );
};
