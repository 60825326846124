import { useState } from "react";
import { API } from "sdk";

import { getItem, setItem } from "hooks/useLocalStorage";
import { useGlobalState } from "./useGlobalState";
import { useQuery as useReactQuery, UseQueryOptions } from "react-query";
import { OnlyError, RemoveError, RemoveHeaders } from "types/api";

export const useMutation = <
  Params extends any,
  PromiseRes extends Promise<any>,
  Res extends PromiseRes extends Promise<infer A> ? A : never
>(
  queryFn: (data: Params) => PromiseRes,
  { onSuccess }: { onSuccess: (res: RemoveError<Res>) => any }
) => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<OnlyError<Res> | null>(null);

  const mutate = (data: Params) => {
    setIsLoading(true);
    setError(null);
    queryFn(data).then((res) => {
      setIsLoading(false);
      if (isError(res)) setError(res as any);
      else onSuccess(res as any);
    });
  };
  return { mutate, error, isLoading };
};

export const useQuery = <Res extends any>(
  queryKey: string | readonly unknown[],
  queryFn: () => Promise<Res>,
  options?: Omit<UseQueryOptions<Res, unknown, Res, string | readonly unknown[]>, "queryKey" | "queryFn">
) => {
  const { data, error, ...rest } = useReactQuery(queryKey, queryFn, options);
  return {
    data: isError(data) ? null : (data as RemoveError<Res>),
    error: isError(data) ? (data as OnlyError<Res>) : null,
    ...rest,
  };
};

export const useAuth = <Params extends { headers: { token: string } }, Return>(
  fn: (data: Params) => Promise<Return>
) => {
  const [chef, setChef] = useGlobalState("chef");
  const { accessToken, refreshToken } = chef;

  return (data: RemoveHeaders<Params>) =>
    fn({ ...data, headers: { token: accessToken } } as Params).then(async (res) => {
      if (isError(res) && (res as any).error.name === "Expired token" && refreshToken)
        return await API.chef.refreshToken({ headers: { refreshtoken: refreshToken } }).then(async (newAccessToken) => {
          if (isError(newAccessToken)) {
            setItem("accessToken", "");
            setItem("refreshToken", "");
            setChef({ ...chef, accessToken: "", refreshToken: "" });
            return Promise.resolve(newAccessToken);
          }
          if (getItem("accessToken")) setItem("accessToken", newAccessToken);
          setChef({ ...chef, accessToken: newAccessToken });
          return await fn({ ...data, headers: { token: newAccessToken } } as Params);
        });
      return res;
    });
};

type BridgeError = { error: { status: number } };
export const isError = (obj: any): obj is BridgeError => typeof obj === "object" && "error" in obj;
