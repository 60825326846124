import { atom, useRecoilState } from "recoil";
import { ChefType } from "types/api";

type StateTypes = {
  chef: {
    isAuthenticated: boolean | null;
    refreshToken: null | string;
    accessToken: string | null;
    me: ChefType | null;
  };
};

const states: { [Key in keyof StateTypes]: { default: StateTypes[Key]; key: Key } } = {
  chef: { default: { isAuthenticated: null, accessToken: null, refreshToken: null, me: null }, key: "chef" },
} as const;

const stateAtoms = {
  chef: atom(states.chef),
} as const;

export const useGlobalState = <KeyType extends keyof typeof stateAtoms>(key: KeyType) => {
  return useRecoilState<StateTypes[KeyType]>(stateAtoms[key] as any);
};
