import z from "zod";
import { LockClosedIcon } from "@heroicons/react/solid";
import { zodResolver } from "@hookform/resolvers/zod";
import { Spinner } from "components/Spinner";
import { useMutation } from "hooks/useBridge";
import { useForm } from "hooks/useForm";
import { useGlobalState } from "hooks/useGlobalState";
import { setItem } from "hooks/useLocalStorage";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { API } from "sdk";

export const Signin = () => {
  const { t } = useTranslation("signin");
  const navigate = useNavigate();
  const location = useLocation();
  const params = new URLSearchParams(location.search);

  const [chef, setChef] = useGlobalState("chef");

  const { mutate, error, isLoading } = useMutation(API.chef.signin, {
    onSuccess: (res) => {
      setItem("accessToken", res.accessToken);
      setItem("refreshToken", res.refreshToken);
      setChef({ ...res, me: null, isAuthenticated: true });
      navigate(params.get("from") || "/");
    },
  });

  useEffect(() => {
    if (chef.isAuthenticated) navigate("/");
  }, [chef.isAuthenticated]);

  const { register, handleSubmit, errors } = useForm(mutate, {
    resolver: zodResolver(z.object({ body: API.chef.zodSchemas.signin.body })),
  });

  return (
    <div className="flex w-full">
      <div className="flex-1 hidden min-h-screen md:flex">
        <img src={require("assets/signin.jpg")} className="fixed object-cover w-1/2 h-screen max-h-screen " />
      </div>
      <div className="flex items-center justify-center flex-1 px-4 overflow-auto sm:px-6 lg:px-8">
        <div className="w-full max-w-md space-y-8">
          <div className="mt-24">
            <h2 className="mt-6 text-4xl font-extrabold">{t("Login")}</h2>
            <p className="mt-4 text-sm text-gray-700">{t("Signin description")}</p>
          </div>
          <form className="mt-4 " onSubmit={handleSubmit}>
            {error && error.error.name === "Document not found" && (
              <p className="p-4 mb-2 text-sm text-red-500 rounded-md bg-red-50">{t(error.error.name)}</p>
            )}
            <div className="-space-y-px rounded-md">
              <div>
                <label htmlFor="email-address" className="font-semibold">
                  {t("Email address")}
                </label>
                <input
                  id="email-address"
                  type="email"
                  autoComplete="email"
                  required
                  className="relative block w-full px-3 py-2 mt-1 mb-4 text-gray-900 placeholder-gray-500 border border-gray-300 rounded-md appearance-none focus:outline-none focus:ring-main focus:border-main focus:z-10 sm:text-sm"
                  placeholder={t("Email address")}
                  {...register("body.email")}
                />
                {errors.body?.email && <p>{t("Invalid email")}</p>}
              </div>
              <div>
                <label htmlFor="password" className="font-semibold">
                  {t("Password")}
                </label>
                <input
                  id="password"
                  type="password"
                  autoComplete="current-password"
                  required
                  className="relative block w-full px-3 py-2 mt-1 text-gray-900 placeholder-gray-500 border border-gray-300 rounded-md appearance-none focus:outline-none focus:ring-main focus:border-main focus:z-10 sm:text-sm"
                  placeholder={t("Password")}
                  {...register("body.password")}
                />
                {error?.error.name === "Invalid password" && <p>{t(error.error.name)}</p>}
              </div>
            </div>

            <div className="flex items-center justify-between mt-4">
              <div className="flex flex-1 text-sm">
                <a href="/reset_password" className="ml-auto font-medium text-main hover:text-main">
                  {t("Forgot your password?")}
                </a>
              </div>
            </div>
            <div className="mt-4">
              <button
                type="submit"
                className="relative flex justify-center w-full px-4 py-2 text-sm font-medium text-white border border-transparent rounded-md bg-main group hover:bg-main focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-main"
              >
                <span className="absolute left-0 flex items-center pl-3 insets-y-0">
                  {isLoading ? (
                    <Spinner />
                  ) : (
                    <LockClosedIcon className="w-5 h-5 text-main group-hover:text-mainLight" aria-hidden="true" />
                  )}
                </span>
                {t("Sign in")}
              </button>
            </div>
          </form>
          <div className="text-sm">
            <a href="/signup" className="font-medium text-main hover:text-main">
              {t("No account? Register now")}
            </a>
          </div>
          <div className="mt-24" />
        </div>
      </div>
    </div>
  );
};
