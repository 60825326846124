import { CheckCircleIcon, XCircleIcon, XIcon } from "@heroicons/react/outline";
import toast from "react-hot-toast";

export const SuccessToast = ({ t, text }: { t: any; text: string }) => {
  return (
    <div
      className={`${
        t.visible ? "animate-enter" : "animate-leave"
      } max-w-sm w-full bg-white shadow-lg rounded-lg pointer-events-auto flex ring-1 ring-black ring-opacity-5`}
    >
      <div className="flex items-start w-full h-full p-4">
        <CheckCircleIcon className="w-8 h-8 text-green-500" />
        <div className="flex flex-col justify-center flex-1 h-full ml-4">
          <p className="text-sm font-semibold">{text}</p>
        </div>
        <button
          onClick={(e) => {
            e.preventDefault();
            toast.remove(t._id);
          }}
          className="flex items-center justify-center p-1 rounded-md hover:bg-gray-50"
        >
          <XIcon className="w-4 h-4 text-gray-700" />
        </button>
      </div>
    </div>
  );
};

export const FailureToast = ({ t, text }: { t: any; text: string }) => {
  return (
    <div
      className={`${
        t.visible ? "animate-enter" : "animate-leave"
      } max-w-sm w-full bg-white shadow-lg rounded-lg pointer-events-auto flex ring-1 ring-black ring-opacity-5`}
    >
      <div className="flex items-start flex-1 w-full h-full p-4">
        <XCircleIcon className="w-8 h-8 text-red" />
        <div className="flex flex-col justify-center flex-1 h-full ml-4">
          <p className="text-sm font-semibold">{text}</p>
        </div>
        <button
          onClick={(e) => {
            e.preventDefault();
            toast.remove(t.id);
          }}
          className="flex items-center justify-center p-1 rounded-md hover:bg-gray-50"
        >
          <XIcon className="w-4 h-4 text-gray-700" />
        </button>
      </div>
    </div>
  );
};
