import { SDKTypes } from './dts/src/index';
import { z } from 'zod';

type ChefT = SDKTypes['chef'];

export class Chef {
  constructor(private Fetch: any) {}

  public zodSchemas = {
    isEmailInUse: {
      query: z.object({ email: z.string().email() }).strict(),
    },
    signin: {
      body: z
  .object({ email: z.string().email(), password: z.string().min(6) })
  .strict(),
    },
    updateMyPassword: {
      body: z
  .object({ oldPassword: z.string().min(6), newPassword: z.string().min(6) })
  .strict(),
      query: z.object({ refreshtoken: z.string() }).strict(),
    },
    updateForgottenPassword: {
      body: z
  .object({ email: z.string().email(), password: z.string().min(6) })
  .strict(),
      query: z.object({ code: z.string() }).strict(),
    },
    refreshToken: {
      query: z.object({ refreshtoken: z.string() }).strict(),
    },
    logout: {
      body: z.object({ all: z.boolean() }).strict(),
      query: z.object({ refreshtoken: z.string() }).strict(),
    },
    deleteMyAccount: {
      body: z.object({ password: z.string() }).strict(),
      query: z.object({ refreshtoken: z.string() }).strict(),
    },
    deleteMyAccountWithoutPassword: {
      body: z.object({ password: z.string() }).strict(),
      query: z.object({ refreshtoken: z.string() }).strict(),
    },
    signup: {
      body: z
  .object({
    firstName: z.string(),
    lastName: z.string(),
    email: z.string().email(),
    password: z.string().min(6),
    language: z.enum(["english", "portuguese"]),
    phone: z.string(),
  })
  .strict(),
    },
    getByAdmin: {
      query: z
  .object({
    chefId: z
      .string()
      .regex(new RegExp("^(?=[a-f\\d]{24}$)(\\d+[a-f]|[a-f]+\\d)")),
  })
  .strict(),
    },
    getById: {
      query: z
  .object({
    chefId: z
      .string()
      .regex(new RegExp("^(?=[a-f\\d]{24}$)(\\d+[a-f]|[a-f]+\\d)")),
  })
  .strict(),
    },
    getPage: {
      body: z
  .object({
    category: z
      .array(
        z.enum([
          "African",
          "Asian",
          "Central-American",
          "European",
          "Frozen",
          "Middle-Eastern",
          "Snacks",
          "South-American",
          "South-Asian",
          "Sweets",
          "Others",
        ])
      )
      .optional(),
    diet: z
      .array(
        z.enum(["Halal", "Vegan", "Vegetarian", "Gluten free", "Dairy Free"])
      )
      .optional(),
    dishType: z
      .array(z.enum(["Starter", "Main", "Dessert", "Snack", "Drinks", "Side"]))
      .optional(),
    withoutAllergens: z
      .array(
        z.enum([
          "Cereals containing gluten",
          "Crustaceans",
          "Eggs",
          "Fish",
          "Peanuts",
          "Soybeans",
          "Milk",
          "Nuts",
          "Celery",
          "Mustard",
          "Sesame seeds",
          "Sulphur dioxide and sulphites",
          "Lupin",
          "Molluscs",
        ])
      )
      .optional(),
    textSearch: z.string().optional(),
    chefName: z.string().optional(),
    positionVisibility: z.number().int().gte(0).optional(),
  })
  .strict(),
    },
    getPageByAdmin: {
      body: z
  .object({
    category: z
      .array(
        z.enum([
          "African",
          "Asian",
          "Central-American",
          "European",
          "Frozen",
          "Middle-Eastern",
          "Snacks",
          "South-American",
          "South-Asian",
          "Sweets",
          "Others",
        ])
      )
      .optional(),
    diet: z
      .array(
        z.enum(["Halal", "Vegan", "Vegetarian", "Gluten free", "Dairy Free"])
      )
      .optional(),
    dishType: z
      .enum(["Starter", "Main", "Dessert", "Snack", "Drinks", "Side"])
      .optional(),
    withoutAllergens: z
      .array(
        z.enum([
          "Cereals containing gluten",
          "Crustaceans",
          "Eggs",
          "Fish",
          "Peanuts",
          "Soybeans",
          "Milk",
          "Nuts",
          "Celery",
          "Mustard",
          "Sesame seeds",
          "Sulphur dioxide and sulphites",
          "Lupin",
          "Molluscs",
        ])
      )
      .optional(),
    textSearch: z.string().optional(),
    chefName: z.string().optional(),
    visibleInApp: z.boolean().optional(),
    positionVisibility: z.number().int().gte(0).optional(),
  })
  .strict(),
      query: z
  .object({ sortOnCreatedAt: z.enum(["-1", "1"]).optional() })
  .strict(),
    },
    updateMe: {
      body: z
  .object({
    firstName: z.string().optional(),
    lastName: z.string().optional(),
    profileImage: z.string().optional(),
    coverImage: z.string().optional(),
    language: z.enum(["english", "portuguese"]).optional(),
    phone: z.string().optional(),
    address: z
      .object({
        type: z.enum(["Point"]),
        coordinates: z.array(z.number()).min(2).max(2),
        name: z.string(),
      })
      .strict()
      .optional(),
    disponibilityString: z.string().optional(),
    disponibilityDays: z.array(z.number().int().gte(0).lte(6)).optional(),
    description: z.string().optional(),
  })
  .strict(),
    },
    updateByAdmin: {
      body: z
  .object({
    firstName: z.string().optional(),
    lastName: z.string().optional(),
    profileImage: z.string().optional(),
    coverImage: z.string().optional(),
    language: z.enum(["english", "portuguese"]).optional(),
    phone: z.string().optional(),
    address: z
      .object({
        type: z.enum(["Point"]),
        coordinates: z.array(z.number()).min(2).max(2),
        name: z.string(),
      })
      .strict()
      .optional(),
    disponibilityString: z.string().optional(),
    disponibilityDays: z.array(z.number().int().gte(0).lte(6)).optional(),
    description: z.string().optional(),
    stars: z.number().int().gte(0).lte(100).optional(),
    numberOfVotes: z.number().int().gte(0).optional(),
    positionVisibility: z.number().int().gte(0).optional(),
  })
  .strict(),
      query: z
  .object({
    chefId: z
      .string()
      .regex(new RegExp("^(?=[a-f\\d]{24}$)(\\d+[a-f]|[a-f]+\\d)")),
  })
  .strict(),
    },
    updateImages: {
      query: z
  .object({
    chefId: z
      .string()
      .regex(new RegExp("^(?=[a-f\\d]{24}$)(\\d+[a-f]|[a-f]+\\d)"))
      .optional(),
  })
  .strict(),
    },
  };

  public isEmailInUse = (p: { query: ChefT['isEmailInUse']['query'] }): Promise<ChefT['isEmailInUse']['return']> => {
    return this.Fetch({ path: '/chef/isEmailInUse', method: 'POST', ...p });
  };

  public signin = (p: { body: ChefT['signin']['body'] }): Promise<ChefT['signin']['return']> => {
    return this.Fetch({ path: '/chef/signin', method: 'POST', ...p });
  };

  public updateMyPassword = (p: { body: ChefT['updateMyPassword']['body']; headers: ChefT['updateMyPassword']['headers'] }): Promise<ChefT['updateMyPassword']['return']> => {
    return this.Fetch({ path: '/chef/updateMyPassword', method: 'POST', ...p });
  };

  public updateForgottenPassword = (p: { body: ChefT['updateForgottenPassword']['body']; query: ChefT['updateForgottenPassword']['query'] }): Promise<ChefT['updateForgottenPassword']['return']> => {
    return this.Fetch({ path: '/chef/updateForgottenPassword', method: 'POST', ...p });
  };

  public refreshToken = (p: { headers: ChefT['refreshToken']['headers'] }): Promise<ChefT['refreshToken']['return']> => {
    return this.Fetch({ path: '/chef/refreshToken', method: 'POST', ...p });
  };

  public logout = (p: { body: ChefT['logout']['body']; headers: ChefT['logout']['headers'] }): Promise<ChefT['logout']['return']> => {
    return this.Fetch({ path: '/chef/logout', method: 'POST', ...p });
  };

  public deleteMyAccount = (p: { body: ChefT['deleteMyAccount']['body']; headers: ChefT['deleteMyAccount']['headers'] }): Promise<ChefT['deleteMyAccount']['return']> => {
    return this.Fetch({ path: '/chef/deleteMyAccount', method: 'POST', ...p });
  };

  public deleteMyAccountWithoutPassword = (p: { body: ChefT['deleteMyAccountWithoutPassword']['body']; headers: ChefT['deleteMyAccountWithoutPassword']['headers'] }): Promise<ChefT['deleteMyAccountWithoutPassword']['return']> => {
    return this.Fetch({ path: '/chef/deleteMyAccountWithoutPassword', method: 'POST', ...p });
  };

  /** The email validation has been removed*/
  public signup = (p: { body: ChefT['signup']['body'] }): Promise<ChefT['signup']['return']> => {
    return this.Fetch({ path: '/chef/signup', method: 'POST', ...p });
  };

  public getByAdmin = (p: { query: ChefT['getByAdmin']['query']; headers: ChefT['getByAdmin']['headers'] }): Promise<ChefT['getByAdmin']['return']> => {
    return this.Fetch({ path: '/chef/getByAdmin', method: 'POST', ...p });
  };

  public getMe = (p: { headers: ChefT['getMe']['headers'] }): Promise<ChefT['getMe']['return']> => {
    return this.Fetch({ path: '/chef/getMe', method: 'POST', ...p });
  };

  /** Anyone can use this*/
  public getById = (p: { query: ChefT['getById']['query'] }): Promise<ChefT['getById']['return']> => {
    return this.Fetch({ path: '/chef/getById', method: 'POST', ...p });
  };

  public getPage = (p: { body: ChefT['getPage']['body']; query: ChefT['getPage']['query'] }): Promise<ChefT['getPage']['return']> => {
    return this.Fetch({ path: '/chef/getPage', method: 'POST', ...p });
  };

  /** All the filters apply actually on chef's dishes.*/
  public getPageByAdmin = (p: { body: ChefT['getPageByAdmin']['body']; query: ChefT['getPageByAdmin']['query']; headers: ChefT['getPageByAdmin']['headers'] }): Promise<ChefT['getPageByAdmin']['return']> => {
    return this.Fetch({ path: '/chef/getPageByAdmin', method: 'POST', ...p });
  };

  public updateMe = (p: { body: ChefT['updateMe']['body']; headers: ChefT['updateMe']['headers'] }): Promise<ChefT['updateMe']['return']> => {
    return this.Fetch({ path: '/chef/updateMe', method: 'POST', ...p });
  };

  public updateByAdmin = (p: { body: ChefT['updateByAdmin']['body']; query: ChefT['updateByAdmin']['query']; headers: ChefT['updateByAdmin']['headers'] }): Promise<ChefT['updateByAdmin']['return']> => {
    return this.Fetch({ path: '/chef/updateByAdmin', method: 'POST', ...p });
  };

  /** Can be used by admin or chef, if the admin use it he has to send chefId in the query parameters.
You need to send a coverImage and/or a profileImage*/
  public updateImages = (p: { query: ChefT['updateImages']['query']; headers: ChefT['updateImages']['headers']; files: Record<string, File> }): Promise<ChefT['updateImages']['return']> => {
    return this.Fetch({ path: '/chef/updateImages', method: 'POST', ...p });
  };
}
