import { CriteriaMode, DefaultValues, FieldValues, Mode, Resolver, useForm as reactHookUseForm } from "react-hook-form";

export type UseFormProps<TFieldValues extends FieldValues = FieldValues, TContext = any> = Partial<{
  mode: Mode;
  reValidateMode: Exclude<Mode, "onTouched" | "all">;
  defaultValues: DefaultValues<TFieldValues>;
  resolver: Resolver<TFieldValues, TContext>;
  context: TContext;
  shouldFocusError: boolean;
  shouldUnregister: boolean;
  shouldUseNativeValidation: boolean;
  criteriaMode: CriteriaMode;
  delayError: number;
}>;

type GetData<T extends (arg: any) => any> = T extends (arg: infer Data) => any ? Data : never;

export const useForm = <Data extends FieldValues, TContext = any>(
  onValid: (data: Data) => any,
  props?: UseFormProps<Data, TContext> & { onInvalid?: (data: Data) => any }
) => {
  const hook = reactHookUseForm<Data, TContext>({ ...props });
  return {
    ...hook,
    errors: hook.formState.errors,
    handleSubmit: hook.handleSubmit(onValid as any, props?.onInvalid as any),
  };
};
