import { ExclamationIcon, TrashIcon } from "@heroicons/react/outline";
import { Overlay } from "components/utils/Overlay";
import { useMutation, useAuth } from "hooks/useBridge";
import { useGlobalState } from "hooks/useGlobalState";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { API } from "sdk";
import { ChefType } from "types/api";

interface ConfirmDeletionModalProps {
  open: boolean;
  setOpen: (o: boolean) => void;
  dish: ChefType["dishes"][number];
}

const ConfirmDeletionModal = ({ open, setOpen, dish }: ConfirmDeletionModalProps) => {
  const { t } = useTranslation("myDishes");
  const [chef, setChef] = useGlobalState("chef");
  const deleteTokenized = useAuth(API.dish.deleteByChef);

  const { mutate: deleteByChef } = useMutation(deleteTokenized, {
    onSuccess: (res) => {
      const newDishes = chef.me?.dishes.filter((dish) => dish._id !== res._id) || [];
      setChef((chef) => ({ ...chef, me: chef.me ? { ...chef.me, dishes: newDishes } : null }));
      setOpen(false);
    },
  });

  return (
    <Overlay open={open} setOpen={setOpen}>
      <div className="px-4 pt-5 pb-4 bg-white sm:p-6 sm:pb-4">
        <div className="sm:flex sm:items-start">
          <div className="flex items-center justify-center flex-shrink-0 w-12 h-12 mx-auto bg-red-100 rounded-full sm:mx-0 sm:h-10 sm:w-10">
            <ExclamationIcon className="w-8 h-8 text-red-600" />
          </div>
          <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
            <h3 className="text-lg font-medium leading-6 text-gray-900" id="modal-title">
              {t("DeleteDish")}: {dish.byChef.name}
            </h3>
            <div className="mt-2">
              <p className="text-sm text-gray-500">{t("DeleteExplanation")}</p>
            </div>
          </div>
        </div>
      </div>
      <div className="px-4 py-3 bg-gray-50 sm:px-6 sm:flex sm:flex-row-reverse">
        <button
          type="button"
          onClick={() => {
            deleteByChef({ query: { dishId: dish._id } });
          }}
          className="inline-flex justify-center w-full px-4 py-2 text-base font-medium text-white bg-red-600 border border-transparent rounded-md hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
        >
          {t("Delete")}
        </button>
        <button
          type="button"
          onClick={() => setOpen(false)}
          className="inline-flex justify-center w-full px-4 py-2 mt-3 text-base font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-main sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
        >
          {t("Cancel")}
        </button>
      </div>
    </Overlay>
  );
};

const DishRow = ({ dish }: { dish: ChefType["dishes"][number] }) => {
  const { i18n } = useTranslation();
  const [openDelete, setOpenDelete] = useState(false);
  return (
    <div className="flex items-center px-4 py-2 border-b border-gray-50">
      <div className="">
        {dish.byChef.images?.[0] ? (
          <img src={dish.byChef.images?.[0]} className="object-cover w-16 h-16 rounded-md" />
        ) : (
          <div className="w-16 h-16 rounded-md bg-main bg-opacity-20" />
        )}
      </div>
      <div className="flex flex-1 ml-4">
        {" "}
        <Link to={`/my-dishes/${dish._id}`}>
          <p className="font-bold text-main">{dish.byChef.name}</p>
          <p className="text-sm text-gray-500 line-clamp-2">{dish.byChef.description}</p>
        </Link>
      </div>
      <div className="flex justify-end co32span-2 w-28">
        <p className="">
          {new Date(dish.createdAt).toLocaleDateString(i18n.language, {
            day: "numeric",
            month: "numeric",
            year: "numeric",
          })}
        </p>
      </div>
      <div className="flex justify-end w-32">
        <ConfirmDeletionModal open={openDelete} setOpen={setOpenDelete} dish={dish} />
        <TrashIcon
          className="w-8 p-1 text-gray-600 border-2 border-transparent rounded-md cursor-pointer bg-gray-50 hover:border-gray-200"
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            setOpenDelete(true);
          }}
        />
      </div>
    </div>
  );
};

const Table = ({ myDishes }: { myDishes: ChefType["dishes"] }) => {
  const { t } = useTranslation("myDishes");
  return (
    <div className="mt-4 bg-white border border-gray-200 rounded-lg">
      <div className="flex px-4 py-4 border-b border-gray-50">
        <div className="w-16 col-span-2">
          <p className="text-sm font-semibold uppercase">{t("Image")}</p>
        </div>
        <div className="flex flex-1 col-span-6 ml-4 ">
          <p className="text-sm font-semibold uppercase">{t("Details")}</p>
        </div>
        <div className="flex justify-end w-32">
          <p className="text-sm font-semibold text-right uppercase">{t("Creation date")}</p>
        </div>
        <div className="flex justify-end w-32">
          <p className="text-sm font-semibold uppercase">{t("Actions")}</p>
        </div>
      </div>
      {myDishes.map((dish) => (
        <DishRow dish={dish} key={dish._id} />
      ))}
    </div>
  );
};

export const MyDishes = () => {
  const { t } = useTranslation("myDishes");
  const [chef] = useGlobalState("chef");

  return (
    <div className="w-full px-8 mx-auto mt-4">
      <div className="flex items-end">
        <p className="text-4xl font-bold">{t("Dish listing")}</p>
        <p className="ml-4 text-lg text-gray-500">
          {chef.me?.dishes.length} {t("items")}
        </p>
        <Link
          to="/add-a-dish"
          className="relative flex justify-center px-4 py-2 ml-auto text-sm font-medium text-white border border-transparent rounded-md bg-main group hover:bg-main focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-main"
        >
          {t("Add a new dish")}
        </Link>
      </div>
      <Table myDishes={chef.me?.dishes || []} />
    </div>
  );
};
